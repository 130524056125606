// ************************ images 2018
import img20181 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-01-960x720.jpg";
import img20182 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-02-1530x2040.jpg";
import img20183 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-03-960x720.jpg";
import img20184 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-04-2040x1530.jpg";
import img20185 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-05-2040x1530.jpg";
import img20186 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-06-2040x1530.jpg";
import img20187 from "../../../assets/img/img-fetefinannee/fetefinannee-2018-07-2040x1530.jpg";
// ************************ images 2019
import img20191 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-01-1600x1200.jpg";
import img20192 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-02-1600x1200.jpg";
import img20193 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-03-1582x883.jpg";
import img20194 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-04-1600x1200.jpg";
import img20195 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-05-1600x1200.jpg";
import img20196 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-06-1600x1200.jpg";
import img20197 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-07-1593x932.jpg";
import img20198 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-08-1600x1200.jpg";
import img20199 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-09-1525x1011.jpg";
import img201910 from "../../../assets/img/img-fetefinannee/fetefinannee-2019-10-1496x1019.jpg";
// ************************ images 2020
import img20201 from "../../../assets/img/img-fetefinannee/fetefinannee-2020-01-1600x900.jpg";
import img20202 from "../../../assets/img/img-fetefinannee/fetefinannee-2020-02-1600x900.jpg";
import img20203 from "../../../assets/img/img-fetefinannee/fetefinannee-2020-03-1600x900.jpg";
import img20204 from "../../../assets/img/img-fetefinannee/fetefinannee-2020-04-1600x900.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabFeteFinAnnee = [
  {
    id: 1,
    season: "2018",
    num: 0,
    date: "",
    comment: "",
    images: [
      { img: img20181, width: "960", height: "720" },
      { img: img20182, width: "1530", height: "2040" },
      { img: img20183, width: "960", height: "720" },
      { img: img20184, width: "2040", height: "1530" },
      { img: img20185, width: "2040", height: "1530" },
      { img: img20186, width: "2040", height: "1530" },
      { img: img20187, width: "2043", height: "1530" },
    ],
  },
  {
    id: 2,
    season: "2019",
    num: 0,
    date: "",
    comment: "",
    images: [
      { img: img20191, width: "1600", height: "1200" },
      { img: img20192, width: "1600", height: "1200" },
      { img: img20193, width: "1582", height: "883" },
      { img: img20194, width: "1600", height: "1200" },
      { img: img20195, width: "1600", height: "1200" },
      { img: img20196, width: "1600", height: "1200" },
      { img: img20197, width: "1593", height: "932" },
      { img: img20198, width: "1600", height: "1200" },
      { img: img20199, width: "1525", height: "1011" },
      { img: img201910, width: "1496", height: "1019" },
    ],
  },
  {
    id: 3,
    season: "2020",
    num: 0,
    date: "",
    comment: "",
    images: [
      { img: img20201, width: "1600", height: "900" },
      { img: img20202, width: "1600", height: "900" },
      { img: img20203, width: "1600", height: "900" },
      { img: img20204, width: "1600", height: "900" },
    ],
  },
];

const photosFeteFinAnnee = tabFeteFinAnnee.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosFeteFinAnnee;
