import { Outlet } from "react-router-dom";
import Header from "../components/sections/s-header";
import NavbarSection from "../components/sections/s-navbar";
import Footer from "../components/sections/s-footer";
import { NavHeightProvider } from "../__utils/context/navheightprovider";
import BtnTopPage from "../__utils/tools/btn-toppage";
import { Container } from "react-bootstrap";
import ScrollToTop from "../__utils/tools/scroll-to-top";

const Layout = () => {
  return (
    <Container fluid className="gx-0">
      <ScrollToTop />
      <Header />
      <NavHeightProvider>
        <NavbarSection />
        <Outlet />
        <BtnTopPage />
      </NavHeightProvider>
      <Footer />
    </Container>
  );
};

export default Layout;
