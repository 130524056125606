import React from "react";

const TournamentBracketBlue = ({ event }) => {
  return (
    <svg
      version="1.1"
      viewBox="0.0 0.0 220 100.0"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      fontFamily="verdana"
    >
      <clipPath id="p.0">
        <path d="m0 0l220.0 0l0 100.0l-220.0 0l0 -100.0z" clipRule="nonzero" />
      </clipPath>
      <defs>
        <filter id="f1" width="10" height="10">
          <feOffset in="SourceGraphic" dx="0" dy="0" />
          <feGaussianBlur stdDeviation="1" />
          <feBlend in="SourceGraphic" in2="blurIn" />
        </filter>
      </defs>

      <g clipPath="url(#p.0)">
        {/* <!-- Première demi-finale --> */}
        {/* <!-- Premier demi-finaliste --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="53"
          height="15"
          x="1"
          y="7.5"
          fill="#002b6eb3"
          filter="url(#f1)"
          stroke="white"
          strokeWidth="0.2"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du premier demi-finaliste --------------------------------------------> */}
        <text
          x="27"
          y="14"
          fontSize="3"
          fill="white"
          textLength="22%"
          textAnchor="middle"
        >
          {event.finalWinnerName}
        </text>
        {/* <!-- Club du premier demi-finaliste --------------------------------------------> */}
        <text x="27" y="19" fontSize="3" fill="white" textAnchor="middle">
          {event.finalWinnerClub}
        </text>
        <path
          stroke="lightblue"
          strokeWidth="0.2"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m54.5 15 l10 0 l0 10 l10 0"
          fillRule="evenodd"
        />
        {/* <!-- Deuxième demi-finaliste --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="53"
          height="15"
          x="1"
          y="27.5"
          fill="#002b6eb3"
          filter="url(#f1)"
          stroke="white"
          strokeWidth="0.2"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du deuxième demi-finaliste --------------------------------------------> */}
        <text
          x="27"
          y="34"
          fontSize="3"
          fill="white"
          textLength="22%"
          textAnchor="middle"
        >
          {event.semiFinalLoser1Name}
        </text>
        {/* <!-- Club du deuxième demi-finaliste --------------------------------------------> */}
        <text
          x="27"
          y="39"
          fontSize="3"
          fontWeight="light"
          fill="white"
          textAnchor="middle"
        >
          {event.semiFinalLoser1Club}
        </text>
        <path
          stroke="lightblue"
          strokeWidth="0.2"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m54.5 35 l10 0 l0 -10 l10 0"
          fillRule="evenodd"
        />
        {/* <!-- troisème demi-finaliste --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="53"
          height="15"
          x="1"
          y="47.5"
          fill="#002b6eb3"
          filter="url(#f1)"
          stroke="white"
          strokeWidth="0.2"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du troisème demi-finaliste --------------------------------------------> */}
        <text
          x="35"
          y="54"
          fontSize="3"
          fill="white"
          textAnchor="middle"
          textLength="22%"
        >
          {event.finalLoserName}
        </text>
        {/* <!-- Club du troisème demi-finaliste --------------------------------------------> */}
        <text
          x="27"
          y="59"
          fontSize="3"
          fontWeight="fw-lighter"
          fill="white"
          textAnchor="middle"
        >
          {event.finalLoserClub}
        </text>
        <path
          stroke="lightblue"
          strokeWidth="0.2"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m54.5 55 l10 0 l0 10 l10 0"
          fillRule="evenodd"
        />
        {/* <!-- Quatrième demi-finaliste --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="53"
          height="15"
          x="1"
          y="67.5"
          fill="#002b6eb3"
          filter="url(#f1)"
          stroke="white"
          strokeWidth="0.2"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du quatrième demi-finaliste --------------------------------------------> */}
        <text
          x="27"
          y="74"
          fontSize="3"
          fill="white"
          textAnchor="middle"
          textLength="22%"
        >
          {event.semiFinalLoser2Name}
        </text>
        {/* <!-- Club du quatrième demi-finaliste --------------------------------------------> */}
        <text x="27" y="79" fontSize="3" fill="white" textAnchor="middle">
          {event.semiFinalLoser2Club}
        </text>
        <path
          stroke="lightblue"
          strokeWidth="0.2"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m54.5 75 l10 0 l0 -10 l10 0"
          fillRule="evenodd"
        />

        {/* <!-- Finale --> */}
        {/* <!-- Premier finaliste --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="53"
          height="15"
          x="75"
          y="17.5"
          filter="url(#f1)"
          fill="#002b6eb3"
          stroke="lightblue"
          strokeWidth="0.5"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du premier finaliste --------------------------------------------> */}
        <text
          x="101.5"
          y="24"
          fontSize="3"
          fill="white"
          textAnchor="middle"
          textLength="22%"
        >
          {event.finalWinnerName}
        </text>
        {/* <!-- Score du premier finaliste --------------------------------------------> */}
        <text x="102" y="29" fontSize="3" fill="white" textAnchor="middle">
          {event.finalWinnerSemiFinalScore}
        </text>
        <path
          stroke="lightblue"
          strokeWidth="0.2"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m128 25 l10 0 l0 20 l10 0"
          fillRule="evenodd"
        />
        {/* <!-- Deuxième finaliste --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="53"
          height="15"
          x="75"
          y="57.5"
          filter="url(#f1)"
          fill="#002b6eb3"
          stroke="lightblue"
          strokeWidth="0.5"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du deuxième finaliste --------------------------------------------> */}
        <text
          x="102"
          y="64.5"
          fontSize="3"
          fill="white"
          textAnchor="middle"
          textLength="22%"
        >
          {event.finalLoserName}
        </text>
        {/* <!-- Score du deuxième finaliste --------------------------------------------> */}
        <text x="102" y="68.5" fontSize="3" fill="white" textAnchor="middle">
          {event.finalLoserSemiFinaleScore}
        </text>
        <path
          stroke="lightblue"
          strokeWidth="0.2"
          strokeLinejoin="round"
          strokeLinecap="butt"
          d="m128 65 l10 0 l0 -20 l10 0"
          fillRule="evenodd"
        />

        {/* <!-- Finale --> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="9"
          fill="yellow"
          viewBox="0 0 16 16"
          x="175"
          y="22"
        >
          <path
            stroke="orange"
            d="M5.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1q.01.775.056 1.469c.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.5.5 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667q.045-.694.056-1.469z"
          />
        </svg>
        {/* <!-- Vainqueur --------------------------------------------> */}
        <rect
          rx="1"
          ry="1"
          width="70"
          height="20"
          x="149"
          y="35"
          fill="#002b6eb3"
          filter="url(#f1)"
          stroke="aqua"
          strokeWidth="0.8"
          fillOpacity="0.5"
        />
        {/* <!-- Nom du vainqueur --------------------------------------------> */}
        <text
          x="184"
          y="44"
          fontSize="4"
          fill="white"
          textAnchor="middle"
          textLength="30%"
        >
          {event.finalWinnerName}
        </text>
        {/* <!-- Score du vainqueur --------------------------------------------> */}
        <text x="184" y="49" fontSize="3" fill="white" textAnchor="middle">
          {event.finalScore}
        </text>
      </g>
    </svg>
  );
};

export default TournamentBracketBlue;
