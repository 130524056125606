const tabTarifsProduits = [
  {
    validityDate: "2025-03-24",
    isValid: true,
    familles: [
      {
        id: 0,
        famille: "fromage",
        produits: [
          {
            produitId: 0,
            libelle: "Comté 6 mois",
            unite: "250 g",
            prixUnitaire: 4.5,
          },
          {
            produitId: 1,
            libelle: "Comté 6 mois",
            unite: "500 g",
            prixUnitaire: 9,
          },
          {
            produitId: 2,
            libelle: "Comté Juraflore Extra Fruité 12 mois",
            unite: "500 g",
            prixUnitaire: 11,
          },
          // {
          //   produitId: 3,
          //   libelle: "Comté Extra Haut Jura 16 mois",
          //   unite: "250 g",
          //   prixUnitaire: 6.0,
          // },
          {
            produitId: 4,
            libelle: "Comté Extra Haut Jura 16 mois",
            unite: "500 g",
            prixUnitaire: 12,
          },
          {
            produitId: 5,
            libelle: "Morbier au lait cru",
            unite: "500 g",
            prixUnitaire: 9,
          },
          {
            produitId: 6,
            libelle: "Bleu de Gex",
            unite: "500 g",
            prixUnitaire: 9.5,
          },
          {
            produitId: 7,
            libelle: "Raclette au lait cru",
            unite: "500 g",
            prixUnitaire: 8.5,
          },
          {
            produitId: 8,
            libelle: "Tomme mousseron",
            unite: "500 g",
            prixUnitaire: 11,
          },
          // Pas de ventes à Pâques ******************************
          // {
          //   produitId: 9,
          //   libelle: "Mont d'Or petit modèle",
          //   unite: "500 g",
          //   prixUnitaire: 11,
          // },
          {
            produitId: 10,
            libelle: "Mamirolle",
            unite: "300 g",
            prixUnitaire: 6,
          },
          {
            produitId: 11,
            libelle: "Polinois",
            unite: "300 g",
            prixUnitaire: 6,
          },
          // Plus de Grimont *************************************
          // {
          //   produitId: 12,
          //   libelle: "Grimont",
          //   unite: "300 g",
          //   prixUnitaire: 5.5,
          // },
        ],
      },
      {
        id: 1,
        famille: "saucisse",
        produits: [
          {
            produitId: 0,
            libelle: "Saucisse de Morteau",
            unite: "400 g",
            prixUnitaire: 10.5,
          },
          {
            produitId: 1,
            libelle: "Saucisse au Comté",
            unite: "400 g",
            prixUnitaire: 10.5,
          },
        ],
      },
      {
        id: 2,
        famille: "vins ROUGES - Gry Sablon à Èmeringes",
        produits: [
          // {
          //   produitId: 0,
          //   unite: "1",
          //   libelle: "Beaujolais Villages Blanc",
          //   prixUnitaire: 9.0,
          // },
          {
            produitId: 1,
            unite: "1",
            libelle: "Beaujolais Villages Rouge  | 2023",
            prixUnitaire: 8.0,
          },
          {
            produitId: 2,

            unite: "1",
            libelle: "Juliénas | 2023 La Petite Cabane 2*",
            prixUnitaire: 10.0,
          },
          {
            produitId: 3,
            unite: "1",
            libelle: "Morgon | 2023 Douby Or Grands Vins Beaujolais",
            prixUnitaire: 10.0,
          },
          {
            produitId: 4,
            unite: "1",
            libelle: "Fleurie | 2023 Vieilles Vignes 1*",
            prixUnitaire: 10.5,
          },
          {
            produitId: 5,
            unite: "1",
            libelle:
              "Moulin à Vent | 2022 Vieilles Vignes Or Grands Vins du Beaujolais",
            prixUnitaire: 11.5,
          },
        ],
      },
      {
        id: 3,
        famille: "vins BLANCS - Patrice Martin à Chânes",
        produits: [
          {
            produitId: 0,
            unite: "1",
            libelle: "Saint Véran | 2023",
            prixUnitaire: 13,
          },
          {
            produitId: 1,
            unite: "1",
            libelle: "Saint Véran | 2023 Le Poissard",
            prixUnitaire: 18,
          },
          {
            produitId: 2,

            unite: "1",
            libelle: "Pouilly Vinzelles | 2023 Bois Préaux",
            prixUnitaire: 15,
          },
          {
            produitId: 3,
            unite: "1",
            libelle: "Pouillys Fuissé | 2023",
            prixUnitaire: 16.5,
          },
        ],
      },
      {
        id: 4,
        famille: "gaufre",
        produits: [
          {
            produitId: 0,
            unite: "6",
            type: "Gaufre",
            libelle: "Gaufres bressanes",
            prixUnitaire: 4,
          },
        ],
      },
    ],
  },
];

export default tabTarifsProduits;
