import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "../../components/error/c-errorpage";
import {
  Accueil,
  Animations,
  Badminton,
  Competitions,
  Contact,
  Enseignement,
  Events,
  Events30Ans,
  Events40Ans,
  EventsRg2013,
  FeteEcole,
  Jchampt,
  Jtournoi,
  Jumelage,
  LeBureau,
  LeClub,
  MentionsLegales,
  NosInstallations,
  NotreHistoire,
  OctobreRose,
  OuSommesNous,
  Partenaires,
  Pickleball,
  Programme,
  Reservations,
  Schampt,
  SoireeClub,
  Stages,
  Stinterne,
  Stournoi,
  TennisSante,
  Ventes,
} from "./index";

const PublicRoute = () => {
  return (
    <Routes>
      <Route index element={<Accueil />} />
      <Route path="/accueil" element={<Accueil />} />
      {/******************************* Pages Le Club ***************************/}
      <Route path="/leclub" element={<LeClub />} />
      <Route>
        <Route path="/leclub/notrehistoire" element={<NotreHistoire />} />
        <Route path="/leclub/nosinstallations" element={<NosInstallations />} />
        <Route path="/leclub/ousommesnous" element={<OuSommesNous />} />
        <Route path="/leclub/lebureau" element={<LeBureau />} />
        <Route path="/leclub/mentionslegales" element={<MentionsLegales />} />
      </Route>
      {/******************************* Pages Competitions ***************************/}
      <Route path="/competitions" element={<Competitions />} />
      <Route>
        <Route path="/competitions/jchampt" element={<Jchampt />} />
        <Route path="/competitions/schampt" element={<Schampt />} />
        <Route path="/competitions/jtournoi" element={<Jtournoi />} />
        <Route path="/competitions/stournoi" element={<Stournoi />} />
        <Route path="/competitions/stinterne" element={<Stinterne />} />
      </Route>
      {/******************************* Page Enseignement ***************************/}
      <Route path="/enseignement" element={<Enseignement />} />
      <Route>
        <Route path="/enseignement/stages" element={<Stages />} />
        <Route path="/enseignement/tennissante" element={<TennisSante />} />
      </Route>
      {/******************************* Page Contacts *******************************/}
      <Route path="/contact" element={<Contact />} />
      {/******************************* Pages Animations ****************************/}
      <Route path="/animations" element={<Animations />} />
      <Route>
        <Route path="/animations/programme" element={<Programme />} />
        <Route path="/animations/ventes" element={<Ventes />} />
        <Route path="/animations/feteecole" element={<FeteEcole />} />
        <Route path="/animations/soireeclub" element={<SoireeClub />} />
        <Route path="/animations/pickleball" element={<Pickleball />} />
        <Route path="/animations/badminton" element={<Badminton />} />
        <Route path="/animations/octobrerose" element={<OctobreRose />} />
      </Route>
      {/******************************* Page Réservations ***************************/}
      <Route path="/reservations" element={<Reservations />} />
      {/******************************* Pages Évènements ****************************/}
      <Route path="/events" element={<Events />} />
      <Route path="/events/30ans" element={<Events30Ans />} />
      <Route path="/events/40ans" element={<Events40Ans />} />
      <Route path="/events/jumelage" element={<Jumelage />} />
      <Route path="/events/rg2013" element={<EventsRg2013 />} />
      {/******************************* Page Devenez Partenaires ********************/}
      <Route path="/partenaires" element={<Partenaires />} />
      {/******************************* Page Not Found ******************************/}
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

export default PublicRoute;
