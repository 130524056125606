import * as yup from "yup";
import "yup-phone-lite";

const profileSchema = yup.object().shape({
  profileEmail: yup
    .string()
    .email("adresse email incorrecte")
    .required("adresse email manquante"),

  profilePwd: yup
    .string()
    .min(8, "minimun 8 caractères")
    .matches(/[0-9]/, "Doit contenir au moins un nombre.")
    .matches(/[a-z]/, "Doit contenir au moins une lettre minuscule.")
    .matches(/[A-Z]/, "Doit contenir au moins une lettre majuscule.")
    .matches(/[#?!@$%^&*-]/, "Doit contenir au moins un symbole.")
    .required("Mot de passe manquant"),

  // profileConfirmPwd: yup
  //   .string()
  //   .oneOf([yup.ref("profilePwd")], "Le mot de passe ne correspond pas.")
  //   .required("Mot de passe manquant"),

  profileFirstName: yup
    .string()
    .min(2, "trop petit")
    .max(30, "trop long!")
    .required("Prénom manquant"),

  profileLastName: yup
    .string()
    .min(2, "trop petit")
    .max(30, "trop long!")
    .required("Nom manquant"),

  profileBirthdate: yup
    .date("date incorrecte")
    .required("Date de naissance manquante"),

  profilePhone: yup
    .string()
    .phone("FR", "numéro incorrect")
    .required("Numéro de portable manquant"),

  profileStreetName: yup
    .string()
    .min(10, "adresse incomplet")
    .required("Adresse manquante"),

  profileZipCode: yup
    .string()
    .min(5, "5 chiffres mini")
    .max(5, "5 chiffres maxi")
    .required("Code postal manquant"),

  profileCityName: yup
    .string()
    .min(3, "Nom de ville incomplet")
    .required("Nom de ville manquant"),

  profileCountryName: yup
    .string()
    .min(3, "Nom de pays incomplet")
    .required("Pays manquant"),

  // profileCreatedOn: yup.date().default(function () {
  // return new Date();
  // }),
});

export default profileSchema;
