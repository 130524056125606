const profileInitValues = {
  profileUserId: "",
  profileCreatedOn: "",
  profileEmail: "",
  profilePwd: "",
  profileFirstName: "",
  profileLastName: "",
  profileBirthdate: "",
  profilePhone: "",
  profileStreetName: "",
  profileZipCode: "",
  profileCityName: "",
  profileCountryName: "",
  profilePhoto: 0,
};

export default profileInitValues;
