import React, { forwardRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Partners from "../../../components/pages/home/c-partners";
import { Document, Page as ReactPdfPage, pdfjs } from "react-pdf";
import pdfPlaquette from "../../../assets/img/tcfPlaquette.pdf";
import HTMLFlipBook from "react-pageflip";
// import useResizeObserver from "use-resize-observer";
import "../../../assets/css/plaquette.css";

// loading the pre-packaged version of pdfjs to initialize a worker in the browser.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Page = forwardRef(({ pageNumber, pdfScale }, ref) => {
  return (
    <div ref={ref}>
      <ReactPdfPage
        pageNumber={pageNumber}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        scale={pdfScale}
      />
    </div>
  );
});

const Plaquette = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfWidth, setPdfWidth] = useState(0);
  const [pdfHeight, setPdfHeight] = useState(0);

  const onDocumentLoadSuccess = async (pdfObject) => {
    setNumPages(pdfObject.numPages);
    setPageNumber(1);

    const page = await pdfObject.getPage(1);
    setPdfWidth(page.view[2]);
    setPdfHeight(page.view[3]);
  };

  return (
    <main role="main" className="mc-fond-courts-couverts">
      <Container fluid>
        <Row className="pt-3 px-4">
          <h1 className="my-3 mc-rampart mc-rfs-1 text-center mc-title display-6">
            Devenez Partenaire du Club
          </h1>
        </Row>
        <Row className="pt-3 px-4">
          <span className="mb-5 fw-bold mc-rfs-3 bg-info text-light text-center">
            Faites bénéficier vos collaborateurs des structures du Tennis Club
            de Feillens en devenant partenaire.
          </span>
        </Row>
        <Row>
          {/* <div ref={ref}> */}
          <div>
            <Document
              file={pdfPlaquette}
              onLoadSuccess={onDocumentLoadSuccess}
              error="Erreur de chargement du fichier PDF."
              onLoadError={() => {
                console.log("Error during loading process");
              }}
              loading="Chargement du fichier PDF …"
              onLoadProgress={() => console.log("Loading ...")}
              className={"d-inline-block d-flex justify-content-center"}
            >
              {pdfWidth && (
                <HTMLFlipBook
                  width={pdfWidth}
                  height={pdfHeight}
                  maxShadowOpacity={1}
                >
                  {Array.from(new Array(numPages)).map((_, i) => (
                    <Page key={i} pageNumber={pageNumber + i} pdfScale={1} />
                  ))}
                </HTMLFlipBook>
              )}
            </Document>
          </div>
        </Row>
        <Row className="py-3 ps-4">
          <span className="mc-partners fs-1 mc-rampart">Nos partenaires</span>
        </Row>
        <Row className="pt-3">
          <Partners />
        </Row>
      </Container>
    </main>
  );
};

export default Plaquette;
