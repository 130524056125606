// most of photos comes from https://pixabay.com/fr/
import React from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import PublicRoute from "./routepublic/route-public";
import AuthRoute from "../router/auth/route-auth";
import "../assets/css/styles.css";
import { AuthProvider } from "../__utils/context/authprovider";
import { Layout } from "./routeadmin";

const App = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/*" element={<PublicRoute />} />
          <Route path="/admin/*" element={<AuthRoute />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default App;
