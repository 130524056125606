import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../pages/auth/p-login";
import Register from "../../pages/auth/p-register";
// import { Layout } from "../routeadmin";
import ErrorPage from "../../components/error/c-errorpage";
import PrivateRoute from "./route-private";
import AdminRoute from "../routeadmin/route-admin";
// import { AuthProvider } from "../../__utils/context/authprovider";

const AuthRoute = () => {
  return (
    <Routes>
      {/* <Route index element={<Login />} /> */}
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/register" element={<Register />} />
      <Route
        path="/*"
        element={
          <PrivateRoute>
            <AdminRoute />
          </PrivateRoute>
        }
      />
      {/******************************* Page Not Found ******************************/}
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AuthRoute;
