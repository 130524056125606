//************************************************************************** */
//** API used to access supabase data server using "ky" a simpler fetch API  */
//** "ky" is a HTTP client based on the browser fetch API                    */
//************************************************************************** */
// Module to access admin-profiles table and retrieve data of email given as argument.

// import { format } from "date-fns";
import ky from "ky";

const createAdminProfileData = async (values) => {
  // data server access address.

  const adminProfileData = {
    user_id: values.profileUserId,
    created_on: new Date(), // insertion de la date et heure de création du profil
    email_address: values.profileEmail,
    password: values.profilePwd,
    first_name: values.profileFirstName,
    last_name: values.profileLastName,
    phone: values.profilePhone,
    street_name: values.profileStreetName,
    zip_code: values.profileZipCode,
    city_name: values.profileCityName,
    country_name: values.profileCountryName,
    birthdate: values.profileBirthdate,
    photo: 0,
  };

  // conversion de l'objet "values" en chaine JSON
  // const amdimProfileDataJSON = JSON.stringify(adminProfileData);
  // console.log("amdimProfileDataJSON: ", amdimProfileDataJSON);

  const URL = process.env.REACT_APP_SERVER_URL + "register/create_adminprofile";

  try {
    const response = await ky

      .post(URL, {
        json: adminProfileData,
      })
      .json();
    return response;
  } catch (error) {
    return error;
  }
};

export default createAdminProfileData;
