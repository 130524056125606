import * as yup from "yup";

const loginSchema = yup.object().shape({
  userIdAdmin: yup
    .string()
    .email("identifiant incorrect")
    .required("identifiant manquant"),

  pwdAdmin: yup
    .string()
    .min(8, "minimun 8 caractères")
    .matches(/[0-9]/, "Doit contenir au moins un nombre.")
    .matches(/[a-z]/, "Doit contenir au moins une lettre minuscule.")
    .matches(/[A-Z]/, "Doit contenir au moins une lettre majuscule.")
    .matches(/[#?!@$%^&*-]/, "Doit contenir au moins un symbole.")
    .required("Mot de passe manquant"),
});

export default loginSchema;
