const tabMenus = [
  {
    id: 1,
    name: "navbar",
    path: "",
    menus: [
      {
        title: "Navbar",
        showTitle: false,
        links: [
          { subPath: "accueil", linkName: "Accueil" },
          { subPath: "leclub", linkName: "Le Club" },
          { subPath: "enseignement", linkName: "Enseignement" },
          { subPath: "competitions", linkName: "Compétitions" },
          { subPath: "animations", linkName: "Animations" },
          { subPath: "reservations", linkName: "Réservations" },
          { subPath: "events", linkName: "Évènements" },
          { subPath: "partenaires", linkName: "Devenez Partenaires" },
          { subPath: "admin", linkName: "Accès admin" },
        ],
      },
    ],
  },
  {
    id: 2,
    name: "sidemenu",
    path: "/leclub",
    menus: [
      {
        title: "Le Club",
        showTitle: false,
        links: [
          { subPath: "/notrehistoire", linkName: "Notre histoire" },
          { subPath: "/lebureau", linkName: "Le bureau" },
          { subPath: "/nosinstallations", linkName: "Nos installations" },
          { subPath: "/ousommesnous", linkName: "Où sommes-nous ?" },
          { subPath: "/mentionslegales", linkName: "Mentions légales" },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "sidemenu",
    path: "/enseignement",
    menus: [
      {
        title: "Enseignement",
        showTitle: false,
        links: [
          { subPath: "#equipepedagogique", linkName: "Équipe pédagogique" },
          { subPath: "#ecoletennis", linkName: "École de tennis" },
          { subPath: "#tennisadulte", linkName: "Tennis adultes" },
          { subPath: "#stagetennis", linkName: "Stages de tennis" },
          { subPath: "#tennissante", linkName: "Tennis Santé - Bien-Être" },
          { subPath: "#pickleball", linkName: "Pickleball" },
        ],
      },
    ],
  },
  {
    id: 4,
    name: "sidemenu",
    path: "/competitions",
    menus: [
      {
        title: "Comp. Jeunes",
        showTitle: true,
        links: [
          { subPath: "/jchampt", linkName: "Championnats" },
          { subPath: "/jtournoi", linkName: "Tournoi TTJ01" },
        ],
      },
      {
        title: "Comp. Seniors",
        showTitle: true,
        links: [
          { subPath: "/schampt", linkName: "Championnats" },
          { subPath: "/stournoi", linkName: "Tournoi - NC à 4/6" },
          { subPath: "/stinterne", linkName: "Tournoi Interne" },
        ],
      },
    ],
  },
  {
    id: 5,
    name: "breadcrumb",
    path: "/animations",
    menus: [
      {
        title: "Breadcrumb Animations",
        showTitle: false,
        links: [
          { subPath: "/programme", linkName: "Programme" },
          { subPath: "/ventes", linkName: "Vente de produits" },
          { subPath: "/feteecole", linkName: "Fête de l'école de tennis" },
          { subPath: "/soireeclub", linkName: "Soirées Club" },
          { subPath: "/badminton", linkName: "Badminton" },
          { subPath: "/pickleball", linkName: "Pickleball" },
          { subPath: "/octobreRose", linkName: "Octobre Rose" },
        ],
      },
    ],
  },
  {
    id: 6,
    name: "breadcrumb",
    path: "/events",
    menus: [
      {
        title: "Breadcrumb events",
        showTitle: false,
        links: [
          { subPath: "/30ans", linkName: "Fête des 30 ans" },
          { subPath: "/40ans", linkName: "Fête des 40 ans" },
          { subPath: "/jumelage", linkName: "Jumelage avec TC Bad Waldsee" },
          { subPath: "/rg2013", linkName: "TCF à RG" },
        ],
      },
    ],
  },
  {
    id: 7,
    name: "sidemenu",
    path: "/animations/programme",
    menus: [
      {
        title: "Menu Activités",
        showTitle: false,
        links: [
          { subPath: "#activitesclub", linkName: "Activités Club" },
          { subPath: "#activitesjeunes", linkName: "Activités Jeunes" },
        ],
      },
    ],
  },
  {
    id: 8,
    name: "sidemenu",
    path: "/admin",
    menus: [
      {
        title: "Menu Administrateur",
        showTitle: false,
        links: [
          { subPath: "/tarifs", linkName: "Tarifs saison" },
          { subPath: "/fichemembre", linkName: "Fiche Membre" },
          { subPath: "/listedesmembres", linkName: "Liste des membres" },
        ],
      },
    ],
  },
  {
    id: 9,
    name: "breadcrumb",
    path: "/enseignement",
    menus: [
      {
        title: "Breadcrumb Enseignement",
        showTitle: false,
        links: [
          { subPath: "/stages", linkName: "Stages" },
          { subPath: "/tennissante", linkName: "Tennis Santé" },
        ],
      },
    ],
  },
];

export default tabMenus;
