import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Modal from "react-bootstrap/Modal";

import * as formik from "formik";
import loginSchema from "../../__params/validationschema/login/loginSchema";
import loginInitValues from "../../__params/validationschema/login/loginInitValues";

import { IconContext } from "react-icons";
import FaIcons from "../../assets/icons/faicons";

import readAdminData from "../../__api/api-admin-readdata";
import CreateProfile from "../../components/pages/auth/c-modal-createprofile";
import { useAuth } from "../../__utils/context/authprovider";
import readAdminProfilesData from "../../__api/api-adminprofile-readdata";

const Register = () => {
  const { Formik } = formik;

  const { logout } = useAuth();

  const [errConnexion, setErrConnexion] = useState("");
  const [errIdentifiant, setErrIdentifiant] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showMsgModal, setShowMsgModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //
  /*************************************************** handle Submit - Register *************/
  const handleRegister = async (values, { resetForm }) => {
    console.log("Appel readAdminData__________________");
    // Check presence of user ID in ADMIN supabase table
    await readAdminData(values.userIdAdmin)
      .then((response) => {
        if (response.success) {
          // Check presence of user ID in "Admin-Profiles" supabase table
          readAdminProfilesData(values.userIdAdmin)
            .then((adminProfileReadResponse) => {
              // If user ID does not exist yet in "Admin-Profiles table
              // then creation of user Admin in auth.users supabase & admin-profiles tables
              if (!adminProfileReadResponse.success) {
                setErrConnexion("");

                // Display Profile Modal and creation of admin profile *************************/
                setShowProfileModal(true);
                setShowMsgModal(true);
                setEmail(values.userIdAdmin);
                setPassword(values.pwdAdmin);
                resetForm();
              } else {
                // If user exists then exit with info message
                setErrIdentifiant("Ce compte existe déjà !");
              }
            })
            .catch((err) => {
              setErrConnexion(err);
            });
        } else {
          // User not allowed to create his account ****************************************/
          setErrIdentifiant(
            "Identifiant erroné ou vous n'êtes pas un membre du bureau du TCF !"
          );
          setShowProfileModal(false);
          setShowMsgModal(false);
          setEmail("");
          setPassword("");
          resetForm();
        }
      })
      // Error accessing table: Admin *******************************
      .catch((err) => {
        setErrConnexion(err);
      });
  };
  /*************************************************** end handle Submit ********************/

  return (
    <section>
      <Container
        fluid
        className="gx-0"
        style={{ backgroundColor: "rgb(197, 252, 231)" }}
      >
        <Row className="gx-0">
          {/* Photo de fond de page register */}
          <Col md={6}>
            <div
              className="g-0 d-none d-md-block mc-fond-register"
              style={{ height: "60vh" }}
            ></div>
          </Col>

          <Col md={6}>
            {/* Formik form */}
            <Formik
              initialValues={loginInitValues}
              validationSchema={loginSchema}
              onSubmit={handleRegister}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                isSubmitting,
                values,
                touched,
                errors,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="h-100"
                  // style={{ height: "auto" }}
                >
                  <Row className="gx-0">
                    <h1 className="mc-rampart text-success text-center my-4">
                      Gestion des comptes
                    </h1>
                    {/******************************** register successful message *************************/}
                    {successMsg && (
                      <Modal
                        show={showMsgModal}
                        onHide={() => {
                          setShowMsgModal(false);
                          logout();
                        }}
                        centered
                        keyboard
                        animation
                        contentClassName="w-75"
                      >
                        <Modal.Body className="bg-success rounded border border-light text-center mc-rfs-4 text-light fw-bold fst-italic">
                          {successMsg}
                        </Modal.Body>
                      </Modal>
                    )}
                  </Row>
                  <Row className="gx-0">
                    {/* Identifiant */}
                    <Form.Group
                      className="w-75 mx-auto my-2"
                      controlId="validationFormikUserIdAdmin"
                    >
                      <Form.Label className="mc-rfs-6 m-0 text-success fw-bold">
                        Email
                      </Form.Label>

                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-success mb-2"
                        type="text"
                        placeholder="email@xxx.com"
                        name="userIdAdmin"
                        value={values.userIdAdmin}
                        aria-describedby="inputGroupPrepend"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.userIdAdmin && !errors.userIdAdmin}
                        isInvalid={touched.userIdAdmin && !!errors.userIdAdmin}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.userIdAdmin}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Mot de passe */}
                    <Form.Group
                      className="w-75 my-2 mx-auto"
                      controlId="validationFormikPwdAdmin"
                    >
                      <Form.Label className="mc-rfs-6 m-0 text-success fw-bold">
                        Mot de passe :
                      </Form.Label>
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-success"
                        type="password"
                        placeholder="entrez le mot passe"
                        name="pwdAdmin"
                        value={values.pwdAdmin}
                        aria-describedby="inputGroupPrepend"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.pwdAdmin ?? !errors.pwdAdmin}
                        isInvalid={touched.pwdAdmin && !!errors.pwdAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pwdAdmin}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="gx-0 my-4">
                    {/******************************** register error message *************************/}
                    <Row className="justify-content-center">
                      {errConnexion && (
                        <h3 className="text-danger text-center fw-bold fst-italic">
                          {errConnexion}
                        </h3>
                      )}
                      {errIdentifiant && (
                        <div className="text-danger text-center fw-bold fst-italic">
                          {errIdentifiant}
                        </div>
                      )}
                    </Row>
                    {/* Bouton de création du compte */}
                    <Button
                      disabled={
                        isSubmitting || errors.userIdAdmin || errors.pwdAdmin
                      }
                      type="submit"
                      className="mc-btn-neon mt-5 mx-auto px-4 d-block w-50"
                      style={{ backgroundColor: "rgb(8, 171, 44)" }}
                    >
                      <span className="fw-bold mc-rfs-5 pe-2">
                        Créer un compte
                      </span>
                      <IconContext.Provider value={{ size: "1rem" }}>
                        <FaIcons.FaChevronRight />
                      </IconContext.Provider>
                    </Button>

                    {/* Lien de navigation vers login */}
                    <Nav className="d-flex justify-content-center mt-4">
                      <Nav.Item>
                        <NavLink to="/admin/auth/login">
                          <span className="mc-rfs-6 fw-bold text-success">
                            Se connecter
                          </span>
                        </NavLink>
                        <span className="mc-rfs-6 fst-italic">
                          {" "}
                          (réservé aux membres du bureau du TCF)
                        </span>
                      </Nav.Item>
                    </Nav>
                  </Row>
                </Form>
              )}
            </Formik>
            {showProfileModal && (
              <CreateProfile
                email={email}
                password={password}
                showProfileModal={showProfileModal}
                setShowProfileModal={setShowProfileModal}
                setSuccessMsg={setSuccessMsg}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Register;
