import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_PUBLIC_KEY;

// console.log("supabaseUrl: ", supabaseUrl);
// console.log("supabaseKey: ", supabaseKey);

if (!supabaseUrl || !supabaseKey) {
  throw new Error(
    "Missing Supabase environment variables. Check that `REACT_APP_SUPABASE_URL` and `REACT_APP_SUPABASE_PUBLIC_KEY` are set in the environment."
  );
}

const supabase = createClient(supabaseUrl, supabaseKey);

export default supabase;
