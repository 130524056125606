// Photos Beach Tennis ****************************************************************//
import imgBeachTennis01 from "../../../assets/img/img-rg2013/01-imgRg-beachTennis-800x600.png";
import imgBeachTennis02 from "../../../assets/img/img-rg2013/02-imgRg-beachTennis-750x507.png";
import imgBeachTennis03 from "../../../assets/img/img-rg2013/03-imgRg-beachTennis-1000x579.png";
import imgBeachTennis04 from "../../../assets/img/img-rg2013/04-imgRg-beachTennis-700x525.png";
import imgBeachTennis05 from "../../../assets/img/img-rg2013/05-imgRg-beachTennis-800x537.png";

// Photos Court Central ***************************************************************//
import imgCentral01 from "../../../assets/img/img-rg2013/01-imgRg-central-1000x448.png";
import imgCentral02 from "../../../assets/img/img-rg2013/02-imgRg-central-850x454.png";
import imgCentral03 from "../../../assets/img/img-rg2013/03-imgRg-central-800x472.png";

// Photos Jeu sur le court central ****************************************************//
import imgCentralJeu01 from "../../../assets/img/img-rg2013/01-imgRg-centralJeu-850x485.png";
import imgCentralJeu02 from "../../../assets/img/img-rg2013/02-imgRg-centralJeu-900x549.png";
import imgCentralJeu03 from "../../../assets/img/img-rg2013/03-imgRg-centralJeu-900x594.png";
import imgCentralJeu04 from "../../../assets/img/img-rg2013/04-imgRg-centralJeu-800x515.png";
import imgCentralJeu05 from "../../../assets/img/img-rg2013/05-imgRg-centralJeu-800x579.png";
import imgCentralJeu06 from "../../../assets/img/img-rg2013/06-imgRg-centralJeu-750x488.png";
import imgCentralJeu07 from "../../../assets/img/img-rg2013/07-imgRg-centralJeu-900x446.png";
import imgCentralJeu08 from "../../../assets/img/img-rg2013/08-imgRg-centralJeu-650x645.png";
import imgCentralJeu09 from "../../../assets/img/img-rg2013/09-imgRg-centralJeu-800x514.png";
import imgCentralJeu10 from "../../../assets/img/img-rg2013/10-imgRg-centralJeu-600x630.png";
import imgCentralJeu11 from "../../../assets/img/img-rg2013/11-imgRg-centralJeu-600x800.png";
import imgCentralJeu12 from "../../../assets/img/img-rg2013/12-imgRg-centralJeu-800x492.png";
import imgCentralJeu13 from "../../../assets/img/img-rg2013/13-imgRg-centralJeu-750x563.png";
import imgCentralJeu14 from "../../../assets/img/img-rg2013/14-imgRg-centralJeu-800x525.png";
import imgCentralJeu15 from "../../../assets/img/img-rg2013/15-imgRg-centralJeu-800x552.png";

// Photos Coupe des Mousquetaires *****************************************************//
import imgCoupeRG01 from "../../../assets/img/img-rg2013/01-imgRg-coupeRG-750x649.png";
import imgCoupeRG02 from "../../../assets/img/img-rg2013/02-imgRg-coupeRG-700x658.png";
import imgCoupeRG03 from "../../../assets/img/img-rg2013/03-imgRg-coupeRG-600x556.png";
import imgCoupeRG04 from "../../../assets/img/img-rg2013/04-imgRg-coupeRG-650x611.png";
import imgCoupeRG05 from "../../../assets/img/img-rg2013/05-imgRg-coupeRG-700x567.png";
import imgCoupeRG06 from "../../../assets/img/img-rg2013/06-imgRg-coupeRG-650x672.png";
import imgCoupeRG07 from "../../../assets/img/img-rg2013/07-imgRg-coupeRG-700x573.png";
import imgCoupeRG08 from "../../../assets/img/img-rg2013/08-imgRg-coupeRG-650x592.png";
import imgCoupeRG09 from "../../../assets/img/img-rg2013/09-imgRg-coupeRG-700x557.png";
import imgCoupeRG10 from "../../../assets/img/img-rg2013/10-imgRg-coupeRG-700x601.png";

// Photos du couloir d'accès au court central ****************************************//
import imgCouloir01 from "../../../assets/img/img-rg2013/01-imgRg-couloir-700x555.png";
import imgCouloir02 from "../../../assets/img/img-rg2013/02-imgRg-couloir-700x681.png";

// Photos Pause Photos ***************************************************************//
import imgPhotos01 from "../../../assets/img/img-rg2013/01-imgRg-photos-600x730.png";
import imgPhotos02 from "../../../assets/img/img-rg2013/02-imgRg-photos-850x555.png";
import imgPhotos03 from "../../../assets/img/img-rg2013/03-imgRg-photos-700x654.png";
import imgPhotos04 from "../../../assets/img/img-rg2013/04-imgRg-photos-650x606.png";
import imgPhotos06 from "../../../assets/img/img-rg2013/06-imgRg-photos-750x581.png";
import imgPhotos07 from "../../../assets/img/img-rg2013/07-imgRg-photos-600x641.png";
import imgPhotos08 from "../../../assets/img/img-rg2013/08-imgRg-photos-650x656.png";

// Photos Roland Garros **************************************************************//
import imgRg01 from "../../../assets/img/img-rg2013/01-imgRg-rg-1211x694.jpg";
import imgRg02 from "../../../assets/img/img-rg2013/02-imgRg-rg-1211x694.jpg";
import imgRg03 from "../../../assets/img/img-rg2013/03-imgRg-rg-750x563.png";

// Photos Salle de presse ************************************************************//
import imgSalleDePresse01 from "../../../assets/img/img-rg2013/01-imgRg-salleDePresse-900x476.png";
import imgSalleDePresse02 from "../../../assets/img/img-rg2013/02-imgRg-salleDePresse-900x541.png";
import imgSalleDePresse03 from "../../../assets/img/img-rg2013/03-imgRg-salleDePresse-900x574.png";
import imgSalleDePresse04 from "../../../assets/img/img-rg2013/04-imgRg-salleDePresse-800x495.png";
import imgSalleDePresse05 from "../../../assets/img/img-rg2013/05-imgRg-salleDePresse-850x475.png";

// Photos Salon **********************************************************************//
import imgSalon01 from "../../../assets/img/img-rg2013/01-imgRg-salon-850x497.png";
import imgSalon02 from "../../../assets/img/img-rg2013/02-imgRg-salon-850x591.png";
import imgSalon03 from "../../../assets/img/img-rg2013/03-imgRg-salon-700x576.png";
import imgSalon04 from "../../../assets/img/img-rg2013/04-imgRg-salon-700x549.png";
import imgSalon05 from "../../../assets/img/img-rg2013/05-imgRg-salon-750x494.png";

// Photos Vestiaires joueurs ********************************************************//
import imgVestaires01 from "../../../assets/img/img-rg2013/01-imgRg-vestiaires-700x625.png";
import imgVestaires02 from "../../../assets/img/img-rg2013/02-imgRg-vestiaires-900x535.png";
import imgVestaires03 from "../../../assets/img/img-rg2013/03-imgRg-vestiaires-800x479.png";
import imgVestaires04 from "../../../assets/img/img-rg2013/04-imgRg-vestiaires-650x654.png";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabRg2013 = [
  {
    id: 1,
    title: "Roland Garros - Stade et place des mousquetaires",
    images: [
      { img: imgRg01, width: "1211", height: "694" },
      { img: imgRg02, width: "1211", height: "694" },
      { img: imgRg03, width: "750", height: "563" },
    ],
  },
  {
    id: 2,
    title: "Salle de presse pour les vainqueurs",
    images: [
      { img: imgSalleDePresse01, width: "800", height: "495" },
      { img: imgSalleDePresse02, width: "850", height: "475" },
      { img: imgSalleDePresse03, width: "900", height: "476" },
      { img: imgSalleDePresse04, width: "900", height: "541" },
      { img: imgSalleDePresse05, width: "900", height: "574" },
    ],
  },
  {
    id: 3,
    title: "Vestiaires des joueurs",
    images: [
      { img: imgVestaires01, width: "700", height: "625" },
      { img: imgVestaires02, width: "900", height: "535" },
      { img: imgVestaires03, width: "800", height: "479" },
      { img: imgVestaires04, width: "650", height: "654" },
    ],
  },
  {
    id: 4,
    title: "Couloir d'accès au court central",
    images: [
      { img: imgCouloir01, width: "900", height: "535" },
      { img: imgCouloir02, width: "700", height: "681" },
    ],
  },
  {
    id: 5,
    title: "Le Central",
    images: [
      { img: imgCentral01, width: "1000", height: "448" },
      { img: imgCentral02, width: "850", height: "454" },
      { img: imgCentral03, width: "800", height: "472" },
    ],
  },
  {
    id: 6,
    title: "Entraînement du TCF sur le Central ou le Lenglen",
    images: [
      { img: imgCentralJeu01, width: "850", height: "485" },
      { img: imgCentralJeu02, width: "900", height: "549" },
      { img: imgCentralJeu03, width: "900", height: "594" },
      { img: imgCentralJeu04, width: "800", height: "515" },
      { img: imgCentralJeu05, width: "800", height: "579" },
      { img: imgCentralJeu06, width: "750", height: "488" },
      { img: imgCentralJeu07, width: "900", height: "446" },
      { img: imgCentralJeu08, width: "650", height: "645" },
      { img: imgCentralJeu09, width: "800", height: "514" },
      { img: imgCentralJeu10, width: "600", height: "630" },
      { img: imgCentralJeu11, width: "600", height: "800" },
      { img: imgCentralJeu12, width: "800", height: "492" },
      { img: imgCentralJeu13, width: "750", height: "563" },
      { img: imgCentralJeu14, width: "800", height: "525" },
      { img: imgCentralJeu15, width: "800", height: "552" },
    ],
  },
  {
    id: 7,
    title: "Pause photos avec nos entraineurs du jour",
    images: [
      { img: imgPhotos01, width: "600", height: "730" },
      { img: imgPhotos02, width: "850", height: "555" },
      { img: imgPhotos03, width: "700", height: "654" },
      { img: imgPhotos04, width: "650", height: "606" },
      { img: imgPhotos06, width: "750", height: "581" },
      { img: imgPhotos07, width: "600", height: "641" },
      { img: imgPhotos08, width: "650", height: "656" },
    ],
  },
  {
    id: 8,
    title: "Salon de réception du central",
    images: [
      { img: imgSalon01, width: "850", height: "497" },
      { img: imgSalon02, width: "850", height: "591" },
      { img: imgSalon03, width: "700", height: "576" },
      { img: imgSalon04, width: "700", height: "549" },
      { img: imgSalon05, width: "750", height: "494" },
    ],
  },
  {
    id: 9,
    title: "Coupe des Mousquetaires",
    images: [
      { img: imgCoupeRG01, width: "750", height: "649" },
      { img: imgCoupeRG02, width: "700", height: "658" },
      { img: imgCoupeRG03, width: "600", height: "556" },
      { img: imgCoupeRG04, width: "650", height: "611" },
      { img: imgCoupeRG05, width: "700", height: "567" },
      { img: imgCoupeRG06, width: "650", height: "672" },
      { img: imgCoupeRG07, width: "700", height: "573" },
      { img: imgCoupeRG08, width: "650", height: "592" },
      { img: imgCoupeRG09, width: "700", height: "557" },
      { img: imgCoupeRG10, width: "700", height: "601" },
    ],
  },
  {
    id: 10,
    title: "Initiation du TCF au Beach Tennis",
    images: [
      { img: imgBeachTennis01, width: "800", height: "600" },
      { img: imgBeachTennis02, width: "750", height: "507" },
      { img: imgBeachTennis03, width: "1000", height: "579" },
      { img: imgBeachTennis04, width: "700", height: "525" },
      { img: imgBeachTennis05, width: "800", height: "537" },
    ],
  },
];

const photosTCFaRG = tabRg2013.map((photo) => ({
  id: photo.id,
  title: photo.title,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosTCFaRG;
