// ************************ images 2024
import img20241 from "../../../assets/img/img-octobrerose/imgOctobreRose-2024-01-874x500.png";
import img20243 from "../../../assets/img/img-octobrerose/imgOctobreRose-2024-03-1068x1520.jpg";
import img20244 from "../../../assets/img/img-octobrerose/imgOctobreRose-2024-04-590x300.png";
import img20245 from "../../../assets/img/img-octobrerose/imgOctobreRose-2024-05-800x472.png";
import img20246 from "../../../assets/img/img-octobrerose/imgOctobreRose-2024-06-851x500.png";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabOctobreRose = [
  {
    id: 1,
    season: "2025",
    sessions: [
      {
        date: "19/10/2024",
        num: 0,
        comment: "Animation au profit de l'association `Les fées papillons`",
        images: [
          { img: img20241, width: "874", height: "500" },
          { img: img20243, width: "1068", height: "1520" },
          { img: img20244, width: "590", height: "300" },
          { img: img20245, width: "800", height: "472" },
          { img: img20246, width: "851", height: "500" },
        ],
      },
    ],
  },
];

const photosOctobreRose = tabOctobreRose.map((photo) => ({
  id: photo.id,
  season: photo.season,
  sessions: photo.sessions.map((session) => {
    return {
      date: session.date,
      num: session.num,
      comment: session.comment,
      images: session.images.map((image) => {
        return {
          src: image.img,
          width: image.width,
          height: image.height,
          srcSet: breakpoints.map((breakpoint) => {
            const height = Math.round(
              (image.height / image.width) * breakpoint
            );
            return {
              src: image.img,
              width: breakpoint,
              height: height,
            };
          }),
        };
      }),
    };
  }),
}));

export default photosOctobreRose;
