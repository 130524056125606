import imgnews0 from "../../../assets/img/img-news/article-progres-20241229-450x300.png";
import imgnews1 from "../../../assets/img/img-news/Banner-TMC-TCF-450x300.png";
import imgnews2 from "../../../assets/img/img-news/inscriptions-450x300.png";
import imgnews3 from "../../../assets/img/img-news/retardataire-450x300.png";
import imgnews4 from "../../../assets/img/img-pickleball/imgPickleball-jeu-450x300.png";
import imgnews5 from "../../../assets/img/img-news/choucroute-2025-450x300.png";
import imgnews6 from "../../../assets/img/img-news/bad-202503-382x270.png";
import imgnews7 from "../../../assets/img/img-news/pickleball-202502-450x300.png";
// import imgnews6 from "../../../assets/img/img-news/bonneAnnee2025-450x300.png";
// import imgnewsX from "../../../assets/img/img-pickleball/imgPickleball-affiche-202411-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/newBadge-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/repriseCours-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/TCF-OctobreRose2024-1068x632.jpeg";
// import imgnewsX from "../../../assets/img/img-news/ag-2023-tcf-450x300.png";
// import imgnewsX from "../../../assets/img/img-news/inscriptionTournoi-450x300.png";
const tableNews = [
  // {
  //   id: 6,
  //   img: imgnews6,
  //   title: "BONNE ANNÉE 2025 !!",
  //   texte: "Le TCF vous présente ses meilleurs voeux pour 2025 et de ",
  //   texteGras: "nombreuses victoires pour 2025 !!",
  //   link: "#",
  // },
  {
    id: 6,
    img: imgnews6,
    title: "Badminton 2025",
    texte: "Amical - Ouvert à tous, compétiteurs ou non.",
    texteGras: "INSCRIVEZ-VOUS ! 🙋‍♂️🙋🏻‍♀️ 📞 ➜ 06 59 52 03 88 ...",
    link: "/animations/badminton",
  },
  {
    id: 7,
    img: imgnews7,
    title: "Pickleball 2025",
    texte: "Amical - Ouvert à tous, à pratiquer en famille ou entre amis.",
    texteGras: "INSCRIVEZ-VOUS ! 🙋‍♂️🙋🏻‍♀️ 📞 ➜ 06 59 52 03 88 ...",
    link: "/animations/pickleball",
  },
  {
    id: 5,
    img: imgnews5,
    title: "Choucroute 2025",
    texte: "Vente de choucroute et de bières allemandes. Vite, ",
    texteGras: "télécharger le bon de commande ...",
    link: "#",
  },
  {
    id: 0,
    img: imgnews0,
    title: "Vente de fromage",
    texte:
      "Merci à tous pour votre participation à la vente de fromages (source article du Progrès Jeannine Besson du 29/12/2024). ",
    texteGras: "RDV à Pâques pour la prochaine vente. On compte sur vous.",
    link: "/animations/ventes",
  },
  {
    id: 1,
    img: imgnews1,
    title: "TMC - Tournoi Multi-Chances",
    texte: "Compétition interne. ",
    texteGras: "Le TMC Dames est programmé le dimanche 23/2.",
    link: "#",
  },
  {
    id: 2,
    img: imgnews2,
    title: "Adhésion au club 2025",
    texte: "Pour les enfants intéressés, il n'est pas trop tard. ",
    texteGras: "Contactez-nous ...",
    link: "/contact",
  },
  {
    id: 3,
    img: imgnews3,
    title: "Reprise des cours 2025",
    texte: "Pour les adultes interessés, il n'est pas trop tard. ",
    texteGras: "Contactez-nous ...",
    link: "/contact",
  },
  {
    id: 4,
    img: imgnews4,
    title: "PICKLEBALL 2025",
    texte:
      "NOUVELLE ACTIVITÉ : Une section Pickleball s'ouvre au TC Feillens à partir de septembre 2024. ",
    texteGras: "RENSEIGNEZ-VOUS ...",
    link: "/enseignement#pickleball",
  },
  // {
  //   id: 1,
  //   img: imgnews1,
  //   title: "Tournoi de doubles de Noël",
  //   texte:
  //     "Cette année le tournoi se déroulera du 23/12 au 30/12. Date limite d'inscription le 15/12. Inscrivez-vous auprès de Fréd.",
  // texteGras: "RENSEIGNEZ-VOUS ...",
  //   link: "/contact",
  // },
  // {
  //   id: 0,
  //   img: imgnewsX,
  //   title: "Tournoi amical de Pickleball",
  //   texte:
  //     "Compétition amicale, ouverte à tous, à pratiquer en famille ou entre amis. INSCRIVEZ-VOUS ! 🙋‍♂️🙋🏻‍♀️ 📞 ➜ 06 59 52 03 88 ...",
  // texteGras: "RENSEIGNEZ-VOUS ...",
  //   link: "/animations/pickleball",
  // },
  // {
  //   id: 1,
  //   img: imgnewsX,
  //   title: "Carte d'accès courts extérieurs 2024",
  //   texte:
  //     "La carte a changé. Pour obtenir la nouvelle clé, il suffit de rapporter l'ancienne aux membres du bureau ...",
  // texteGras: "RENSEIGNEZ-VOUS ...",
  //   link: "/contact",
  // },
  // {
  //   id: 0,
  //   img: imgnewsX,
  //   title: "Après-midi Octobre ROSE",
  //   texte:
  //     "Journée au profit de l'association - Les Fées Papillons. Venez nombreux à partir de 14h ...",
  // texteGras: "RENSEIGNEZ-VOUS ...",
  //   link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  // },
  // {
  //   id: 4,
  //   img: imgnewsX,
  //   title: "Licences d'été 2024",
  //   texte:
  //     "Les adhésions pour bénéficier des courts de tennis durant l'été (juin, juillet, août) sont ouvertes => Tarif Adulte : 55 €. CONTACTEZ-NOUS !",
  // texteGras: "RENSEIGNEZ-VOUS ...",
  //   link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  // },
  // {
  //   id: 5,
  //   img: imgnewsX,
  //   title: "Assemblée Générale 2023",
  //   texte: "Samedi 21/10/2023 à 18h au clubhouse.",
  // texteGras: "RENSEIGNEZ-VOUS ...",
  //   link: "https://centrecommercesbourg.fr/commerce/janin-sports/",
  // },
];

export default tableNews;
