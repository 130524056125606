import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Logout from "../../assets/icons/icons8-close-50.png";
import Image from "react-bootstrap/Image";
import { useAuth } from "../context/authprovider";

const BtnExitAdmin = () => {
  const { logout } = useAuth();

  const handleClick = () => {
    logout();
  };

  return (
    <div>
      <OverlayTrigger
        placement={"top"}
        overlay={<Tooltip className="mc-rfs-7">Déconnexion</Tooltip>}
      >
        <Button
          className="p-0 rounded-5 border border-3 border-danger"
          onClick={handleClick}
        >
          <Image
            className="bg-danger-subtle rounded-5"
            src={Logout}
            style={{ height: "30px", width: "30px" }}
          />
        </Button>
      </OverlayTrigger>
    </div>
  );
};

export default BtnExitAdmin;
