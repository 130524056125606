import React from "react";
import MenuBreadcrumb from "../../../components/menus/m-breadcrumb";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CommandeProduits from "../../../components/pages/ventes/c-cde-produits";
import PdfViewer from "../../../__utils/tools/pdf-viewer";
import pdfFile from "../../../assets/img/img-fromage/flyer-2025-paques.pdf";

const Ventes = () => {
  return (
    <main role="main" className="mc-fond-fromage">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumb />
            {/************************** Partie Badminton */}
            <Card bg="transparent" className="m-2 border-0">
              <Card.Header className="mc-rampart mc-rfs-2 m-2 text-primary rounded-2 text-center bg-white">
                Vente de produits locaux
              </Card.Header>
              <Card.Body className="m-2 p-0">
                <Card.Text className="text-light lh-sm mc-rfs-4">
                  Pour les fêtes de Noël, participez à la vente de fromages,
                  charcuterie et vins provenant de producteurs reconnus à
                  présenter sur vos tables durant les repas de famille.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className="bg-transparent flex-lg-row justify-content-between mx-2 mt-3 border-0">
              <Col lg={5}>
                <Card.Body className="m-2 rounded-3 border border-5 border-warning text-center text-light">
                  <PdfViewer pdfFile={pdfFile} />
                </Card.Body>
                <Card.Body className="m-2 rounded-3 border border-5 border-warning text-center">
                  <Card.Title className="text-light">
                    Distribution des Produits
                  </Card.Title>
                  {/* <Figure>
                    <Figure.Image
                      src={imgVenteFromage}
                      alt="..."
                      className="border border-1 border-warning"
                      width={"100%"}
                    />
                    <Figure.Caption className="fw-bold text-light p-2 bg-secondary">
                      L'équipe du TCF vous remercie pour vos commandes et votre
                      soutien !
                    </Figure.Caption>
                  </Figure> */}
                </Card.Body>
              </Col>
              <Col lg={7}>
                <Card.Body
                  className="m-2 rounded-3 border border-5 border-warning bg-gradient"
                  style={{
                    background: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  <CommandeProduits />
                </Card.Body>
              </Col>
            </Card>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Ventes;
