// ************************ images 2017
import img20171 from "../../../assets/img/img-badminton/2017-01-imgBad-892x440.jpg";
// ************************ images 2018
import img20181 from "../../../assets/img/img-badminton/2018-01-imgBad-1282x711.jpg";
import img20182 from "../../../assets/img/img-badminton/2018-02-imgBad-4128x2322.jpg";
import img20183 from "../../../assets/img/img-badminton/2018-03-imgBad-4128x2322.jpg";
import img20184 from "../../../assets/img/img-badminton/2018-04-imgBad-481x647.jpg";
import img20185 from "../../../assets/img/img-badminton/2018-05-imgBad-778x294.jpg";
// ************************ images 2019
import img20191 from "../../../assets/img/img-badminton/2019-01-imgBad-1080x368.jpg";
import img20192 from "../../../assets/img/img-badminton/2019-02-imgBad-1080x810.jpg";
import img20193 from "../../../assets/img/img-badminton/2019-03-imgBad-1080x1440.jpg";
import img20194 from "../../../assets/img/img-badminton/2019-04-imgBad-1080x810.jpg";
// ************************ images 2022
import img20221 from "../../../assets/img/img-badminton/2022-01-imgBad-428x321.jpg";
import img20222 from "../../../assets/img/img-badminton/2022-02-imgBad-296x249.jpg";
import img20223 from "../../../assets/img/img-badminton/2022-03-imgBad-229x304.jpg";
// ************************ images 2023
import img20231 from "../../../assets/img/img-badminton/2023-01-imgBad-4000x1800.jpg";
import img20232 from "../../../assets/img/img-badminton/2023-02-imgBad-1080x810.jpg";
import img20235 from "../../../assets/img/img-badminton/2023-05-imgBad-1024x768.jpg";
import img20233 from "../../../assets/img/img-badminton/2023-03-imgBad-1024x768.jpg";
import img20234 from "../../../assets/img/img-badminton/2023-04-imgBad-1080x810.jpg";
// ************************ images 2024
import img20241 from "../../../assets/img/img-badminton/2024-01-imgBad-1080x1528.jpg";
import img20242 from "../../../assets/img/img-badminton/2024-02-imgBad-1200x482.png";
import img20243 from "../../../assets/img/img-badminton/2024-03-imgBad-1000x594.png";
import img20244 from "../../../assets/img/img-badminton/2024-04-imgBad-1000x587.png";
import img20245 from "../../../assets/img/img-badminton/2024-05-imgBad-258x257.png";
// ************************ images 2025
import img20250 from "../../../assets/img/img-badminton/2025-00-imgBad-382x540.png";
import img20251 from "../../../assets/img/img-badminton/2025-01-imgBadminton-1191x815.png";
import img20252 from "../../../assets/img/img-badminton/2025-02-imgBad-1000x362.png";
import img20253 from "../../../assets/img/img-badminton/2025-03-imgBad-750x556.png";
import img20254 from "../../../assets/img/img-badminton/2025-04-imgBad-800x671.png";
import img20255 from "../../../assets/img/img-badminton/2025-05-imgBad-800x719.png";
import img20256 from "../../../assets/img/img-badminton/2025-06-imgBad-1000x597.png";
import img20257 from "../../../assets/img/img-badminton/2025-07-imgBad-620-453.png";
import img20258 from "../../../assets/img/img-badminton/2025-08-imgBad-950x613.png";
import img20259 from "../../../assets/img/img-badminton/2025-09-imgBad-800x635.png";
import img202510 from "../../../assets/img/img-badminton/2025-10-imgBad-900x584.png";
import img202511 from "../../../assets/img/img-badminton/2025-11-imgBad-1000x559.png";
import img202512 from "../../../assets/img/img-badminton/2025-12-imgBad-750x401.png";
import img202513 from "../../../assets/img/img-badminton/2025-13-imgBad-800x532.png";
import img202514 from "../../../assets/img/img-badminton/2025-14-imgBad-850x491.png";
import img202515 from "../../../assets/img/img-badminton/2025-15-imgBad-950x551.png";
import img202516 from "../../../assets/img/img-badminton/2025-16-imgBad-750x1000.png";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabBadminton = [
  {
    id: 1,
    season: "2017",
    num: 0,
    date: "08/04/2017",
    comment: "",
    images: [
      {
        img: img20171,
        width: "892",
        height: "440",
      },
    ],
  },
  {
    id: 2,
    season: "2018",
    num: 28,
    date: "17/03/2018",
    comment: "",
    images: [
      { img: img20181, width: "1282", height: "711" },
      { img: img20182, width: "4128", height: "2322" },
      { img: img20183, width: "4128", height: "2322" },
      { img: img20184, width: "481", height: "647" },
      { img: img20185, width: "778", height: "294" },
    ],
  },
  {
    id: 3,
    season: "2019",
    num: 36,
    date: "23/03/2019",
    comment: "",
    images: [
      { img: img20191, width: "1080", height: "368" },
      { img: img20192, width: "1080", height: "810" },
      { img: img20193, width: "1080", height: "1440" },
      { img: img20194, width: "1080", height: "810" },
    ],
  },
  {
    id: 4,
    season: "2020",
    num: 0,
    date: "21/03/2020",
    comment: "Annulée suite aux restrictions COVID",
    images: [{}],
  },
  {
    id: 5,
    season: "2021",
    num: 0,
    date: "13/03/2021",
    comment: "Annulée suite aux restrictions COVID",
    images: [{}],
  },
  {
    id: 6,
    season: "2022",
    num: 39,
    date: "12/03/2022",
    comment: "",
    images: [
      { img: img20221, width: "428", height: "321" },
      { img: img20222, width: "296", height: "249" },
      { img: img20223, width: "229", height: "304" },
    ],
  },
  {
    id: 7,
    season: "2023",
    num: 43,
    date: "11/03/2023",
    comment: "",
    images: [
      { img: img20231, width: "4000", height: "1800" },
      { img: img20232, width: "1080", height: "810" },
      { img: img20233, width: "1024", height: "768" },
      { img: img20234, width: "1080", height: "810" },
      { img: img20235, width: "1024", height: "768" },
    ],
  },
  {
    id: 8,
    season: "2024",
    num: 32,
    date: "16/03/2024",
    comment: "",
    images: [
      { img: img20241, width: "1080", height: "1528" },
      { img: img20242, width: "1200", height: "482" },
      { img: img20243, width: "1000", height: "594" },
      { img: img20244, width: "1000", height: "587" },
      { img: img20245, width: "258", height: "257" },
    ],
  },
  {
    id: 9,
    season: "2025",
    num: 36,
    date: "15/03/2025",
    comment: "",
    images: [
      { img: img20250, width: "382", height: "540" },
      { img: img20251, width: "1191", height: "815" },
      { img: img20252, width: "1000", height: "362" },
      { img: img20253, width: "750", height: "556" },
      { img: img20254, width: "800", height: "671" },
      { img: img20255, width: "800", height: "719" },
      { img: img20256, width: "1000", height: "597" },
      { img: img20257, width: "620", height: "453" },
      { img: img20258, width: "950", height: "613" },
      { img: img20259, width: "800", height: "635" },
      { img: img202510, width: "900", height: "584" },
      { img: img202511, width: "1000", height: "559" },
      { img: img202512, width: "750", height: "401" },
      { img: img202513, width: "800", height: "532" },
      { img: img202514, width: "850", height: "491" },
      { img: img202515, width: "950", height: "551" },
      { img: img202516, width: "750", height: "1000" },
    ],
  },
];

const photosBadminton = tabBadminton.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosBadminton;
