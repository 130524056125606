import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const MemberSlipModal = ({ modalShow, setModalShow, member }) => {
  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <Modal show={modalShow} size="lg" onHide={handleClose} className="mc-rfs-5">
      <Modal.Header closeButton>
        <Modal.Title>
          Fiche :{" "}
          <span className="fw-bold">
            {member[2]} {member[3]}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Container>
          <Card className="my-2">
            <Card.Header className="ps-2 py-0 fst-italic">Adhésion</Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Période</b>
                  <p className="mb-0">{member[1]}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Type</b>
                  <p className="mb-0">
                    {member[0] === "Cours" ? "Adhésion + cours" : "Adhésion"}
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Nb heures / semaine</b>
                  <p className="mb-0">
                    {member[18] === "X" ? "2 heures" : "1 heure"}
                  </p>
                </Col>
                <Col xs={6} md={3}>
                  <b>N° de licence</b>
                  <p className="mb-0">
                    {member[6]} {member[7]}
                  </p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="my-2">
            <Card.Header className="ps-2 py-0 fst-italic">
              Informations générales
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Nom - Prénom</b>
                  <p className="mb-0">
                    {member[2]} {member[3]}
                  </p>
                </Col>
                <Col xs={6} md={2}>
                  <b>Civilité</b>
                  <p className="mb-0">{member[4] === "F" ? "Mme" : "Mr"}</p>
                </Col>
                <Col xs={6} md={2}>
                  <b>Nationalité</b>
                  <p className="mb-0">{member[5] === "Française" && "FR"}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Date de Naissance</b>
                  <p className="mb-0">{member[9]}</p>
                </Col>
                <Col xs={6} md={2}>
                  <b>Catégorie</b>
                  <p className="mb-0">{member[10]} ans</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="my-2">
            <Card.Header className="ps-2 py-0 fst-italic">Adresse</Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Résidence, bât, esc.</b>
                  <p className="mb-0">{member[11]}</p>
                </Col>
                <Col xs={6} md={4}>
                  <b>N° de voie</b>
                  <p className="mb-0">{member[12]}</p>
                </Col>
                <Col xs={6} md={2}>
                  <b>CP</b>
                  <p className="mb-0">{member[13]}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Ville</b>
                  <p className="mb-0">{member[14]}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="my-2">
            <Card.Header className="ps-2 py-0 fst-italic">Contact</Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={3}>
                  <b>Tél domicile</b>
                  <p className="mb-0">{member[15]}</p>
                </Col>
                <Col xs={6} md={3}>
                  <b>Tél Portable</b>
                  <p className="mb-0">{member[16]}</p>
                </Col>
                <Col xs={6} md={6}>
                  <b>mail</b>
                  <p className="mb-0">{member[17]}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="my-2">
            <Card.Header className="ps-2 py-0 fst-italic">
              Accès courts
            </Card.Header>
            <Card.Body className="p-2 mc-rfs-6">
              <Row>
                <Col xs={6} md={6}>
                  <b>Clé courts extérieurs</b>
                  <p className="mb-0">{member[19] === "X" ? "OUI" : "NON"}</p>
                </Col>
                <Col xs={6} md={6}>
                  <b>Badge courts couverts</b>
                  <p className="mb-0">{member[20] === "X" ? "OUI" : "NON"}</p>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MemberSlipModal;
