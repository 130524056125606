import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";

import * as formik from "formik";
import profileInitValues from "../../../__params/validationschema/profile/profileInitValues";
import profileSchema from "../../../__params/validationschema/profile/profileSchema";
import { useAuth } from "../../../__utils/context/authprovider";
import createAdminProfileData from "../../../__api/api-adminprofile-createdata";

const CreateProfile = ({
  email,
  password,
  showProfileModal,
  setShowProfileModal,
  setSuccessMsg,
}) => {
  const { Formik } = formik;

  const { adminRegister } = useAuth();

  const [errAdmin, setErrAdmin] = useState("");
  const [show, setShow] = useState(showProfileModal);

  const initValues = {
    ...profileInitValues,
    profileEmail: email,
    profilePwd: password,
  };

  const handleClose = () => {
    setShow(false);
    setShowProfileModal(false);
    setErrAdmin("");
    setSuccessMsg("");
  };

  //****** Signup in supbase & create admin profile *************************/
  const handleSignup = async (values) => {
    // Launch signup method of supabase authentification

    console.log("Appel adminRegister________________");

    // Sign up of admin user ************************************************/
    await adminRegister(email, password)
      .then((response) => {
        console.log("result c-modal: ", response);
        console.log("response.data.user.id: ", response.data.user.id);
        values.profileUserId = response.data.user.id;

        if (response.success) {
          setErrAdmin("");
          setShowProfileModal(false);

          // Create of Admin Profile data ***********************************/
          createAdminProfileData(values)
            .then((adminProfileCreateResponse) => {
              if (adminProfileCreateResponse.success) {
                console.log(
                  "adminProfileData retour CREATE: ",
                  adminProfileCreateResponse
                );
                setSuccessMsg(adminProfileCreateResponse.message);
                // setSuccessMsg(
                //   "Le compte a été créé ! Vous pouvez vous connecter."
                // );
                values.profileEmail = "";
                values.profilePwd = "";
              } else {
                setErrAdmin(adminProfileCreateResponse.message);
              }
            })
            .catch((err) => {
              console.log("err create: ", err);
              setErrAdmin(err);
            });
        } else {
          // Error during account creation process **************************/

          console.log("response.error: ", response.error.message);
          setErrAdmin("Ce compte existe déjà !");
        }
      })
      // Error accessing table: Admin **************************************/
      .catch((err) => {
        setErrAdmin(err);
      });
  };
  //****** Signup end *******************************************************/

  return (
    <section>
      <Formik
        initialValues={initValues}
        validationSchema={profileSchema}
        onSubmit={handleSignup}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form noValidate onSubmit={handleSubmit} className="px-1">
            <Modal show={show}>
              <Modal.Header className="flex-column bg-success-subtle">
                <Modal.Title className="w-100 text-center fw-bold">
                  Création du compte
                </Modal.Title>
              </Modal.Header>
              {/* <Modal.Body hidden={message} className=""> */}
              <Modal.Body className="">
                <Row>
                  {/* Email */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileEmail"
                  >
                    <Form.Label className="mc-rfs-6 m-0">Email</Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        // autoComplete="on"
                        disabled
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="email"
                        placeholder="email@xxx.com"
                        name="profileEmail"
                        value={values.profileEmail}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.profileEmail && !errors.profileEmail}
                        isInvalid={
                          touched.profileEmail && !!errors.profileEmail
                        }
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.profileEmail}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {/* Mot de passe */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfilePwd"
                  >
                    <Form.Label className="mc-rfs-6 m-0">
                      Mot de passe :
                    </Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        // autoComplete="on"
                        disabled
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="password"
                        placeholder="entrez le mot passe"
                        name="profilePwd"
                        value={values.profilePwd}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.profilePwd ?? !errors.profilePwd}
                        isInvalid={touched.profilePwd && !!errors.profilePwd}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.profilePwd}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <hr className="border-2 border-success" />

                <Row>
                  {/* Nom */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileLastName"
                  >
                    <Form.Label className="mc-rfs-6 m-0">Nom</Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        // autoFocus
                        placeholder="Nom"
                        name="profileLastName"
                        value={values.profileLastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileLastName && !errors.profileLastName
                        }
                        isInvalid={
                          touched.profileLastName && !!errors.profileLastName
                        }
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileLastName}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {/* Prénom */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileFirstName"
                  >
                    <Form.Label className="mc-rfs-6 m-0">Prénom</Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="Prénom"
                        name="profileFirstName"
                        value={values.profileFirstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileFirstName ?? !errors.profileFirstName
                        }
                        isInvalid={
                          touched.profileFirstName && !!errors.profileFirstName
                        }
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileFirstName}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {/* Date de naissance */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileBirthdate"
                  >
                    <Form.Label className="mc-rfs-6 m-0">
                      Date de Naissance
                    </Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="date"
                        // placeholder="date"
                        name="profileBirthdate"
                        value={values.profileBirthdate}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileBirthdate ?? !errors.profileBirthdate
                        }
                        isInvalid={
                          touched.profileBirthdate && !!errors.profileBirthdate
                        }
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileBirthdate}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row>
                  {/* Numéro de tél */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfilePhone"
                  >
                    <Form.Label className="mc-rfs-6 m-0">
                      N° Téléphone
                    </Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="06.00.00.00.00"
                        name="profilePhone"
                        value={values.profilePhone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.profilePhone && !errors.profilePhone}
                        isInvalid={
                          touched.profilePhone && !!errors.profilePhone
                        }
                      />

                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profilePhone}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row>
                  {/* Nom de rue */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileStreetName"
                  >
                    <Form.Label className="mc-rfs-6 m-0">N° + Rue</Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="rue / route"
                        name="profileStreetName"
                        value={values.profileStreetName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileStreetName && !errors.profileStreetName
                        }
                        isInvalid={
                          touched.profileStreetName &&
                          !!errors.profileStreetName
                        }
                      />

                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileStreetName}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>

                <Row>
                  {/* Code postal */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileZipCode"
                  >
                    <Form.Label className="mc-rfs-6 m-0">
                      Code Postal
                    </Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="code postal"
                        name="profileZipCode"
                        value={values.profileZipCode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileZipCode && !errors.profileZipCode
                        }
                        isInvalid={
                          touched.profileZipCode && !!errors.profileZipCode
                        }
                      />

                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileZipCode}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {/* Ville */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileCityName"
                  >
                    <Form.Label className="mc-rfs-6  m-0">Ville</Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="ville"
                        name="profileCityName"
                        value={values.profileCityName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileCityName && !errors.profileCityName
                        }
                        isInvalid={
                          touched.profileCityName && !!errors.profileCityName
                        }
                      />

                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileCityName}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  {/* Pays */}
                  <Form.Group
                    as={Col}
                    className="mx-auto my-auto"
                    controlId="validationFormikProfileCountryName"
                  >
                    <Form.Label className="mc-rfs-6 m-0">Pays</Form.Label>
                    <InputGroup hasValidation className="">
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-secondary"
                        type="text"
                        placeholder="pays"
                        name="profileCountryName"
                        value={values.profileCountryName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={
                          touched.profileCountryName &&
                          !errors.profileCountryName
                        }
                        isInvalid={
                          touched.profileCountryName &&
                          !!errors.profileCountryName
                        }
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="mc-rfs-6"
                      >
                        {errors.profileCountryName}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer className=" bg-success-subtle">
                {/******************************** signup error message *************************/}
                {errAdmin && (
                  <div className="w-100 rounded m-2">
                    <h4 className="m-0 text-center text-danger fw-bold fst-italic py-2">
                      {errAdmin}
                    </h4>
                  </div>
                )}
                <Button variant="secondary" size="sm" onClick={handleClose}>
                  Fermer
                </Button>

                <Button
                  variant="primary"
                  // hidden={message}
                  size="sm"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Enregistrer
                </Button>
              </Modal.Footer>
            </Modal>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default CreateProfile;
