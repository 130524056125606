//************************************************************************** */
//** API used to access supabase data server using "ky" a simpler fetch API  */
//** "ky" is a HTTP client based on the browser fetch API                    */
//************************************************************************** */
// Module to access admin table and retrieve data of email given as argument.

import ky from "ky";

const readAdminData = async (email) => {
  // data server access address.
  const URL = process.env.REACT_APP_SERVER_URL + "register/get_admin/" + email;

  try {
    const response = await ky.get(URL).json();
    return response;
  } catch (error) {
    return error;
  }
};

export default readAdminData;
