// ************************ images 2018
import img201802 from "../../../assets/img/img-jumelage/imgJumelage-2018-02-1200x675.jpg";
import img201803 from "../../../assets/img/img-jumelage/imgJumelage-2018-03-720x540.jpg";
import img201801 from "../../../assets/img/img-jumelage/imgJumelage-2018-01-1000x563.jpg";
import img201804 from "../../../assets/img/img-jumelage/imgJumelage-2018-04-1000x563.jpg";
import img201805 from "../../../assets/img/img-jumelage/imgJumelage-2018-05-800x486.jpg";
import img201806 from "../../../assets/img/img-jumelage/imgJumelage-2018-06-600x450.jpg";
import img201807 from "../../../assets/img/img-jumelage/imgJumelage-2018-07-600x450.jpg";
import img201808 from "../../../assets/img/img-jumelage/imgJumelage-2018-08-600x800.jpg";
import img201809 from "../../../assets/img/img-jumelage/imgJumelage-2018-09-800x600.jpg";
import img201810 from "../../../assets/img/img-jumelage/imgJumelage-2018-10-1200x675.jpg";
import img201811 from "../../../assets/img/img-jumelage/imgJumelage-2018-11-800x450.jpg";
import img201812 from "../../../assets/img/img-jumelage/imgJumelage-2018-12-1000x563.jpg";
import img201813 from "../../../assets/img/img-jumelage/imgJumelage-2018-13-900x506.jpg";
import img201814 from "../../../assets/img/img-jumelage/imgJumelage-2018-14-900x506.jpg";
import img201815 from "../../../assets/img/img-jumelage/imgJumelage-2018-15-1000x563.jpg";
import img201816 from "../../../assets/img/img-jumelage/imgJumelage-2018-16-700x394.jpg";
import img201817 from "../../../assets/img/img-jumelage/imgJumelage-2018-17-1000x625.jpg";
import img201818 from "../../../assets/img/img-jumelage/imgJumelage-2018-18-900x506.jpg";
import img201819 from "../../../assets/img/img-jumelage/imgJumelage-2018-19-800x450.jpg";
import img201820 from "../../../assets/img/img-jumelage/imgJumelage-2018-20-900x506.jpg";
import img201821 from "../../../assets/img/img-jumelage/imgJumelage-2018-21-1000x563.jpg";
import img201822 from "../../../assets/img/img-jumelage/imgJumelage-2018-22-900x1131.jpg";
import img201823 from "../../../assets/img/img-jumelage/imgJumelage-2018-23-1000x563.jpg";
import img201824 from "../../../assets/img/img-jumelage/imgJumelage-2018-24-900x506.jpg";
// ************************ images 2019
import img201901 from "../../../assets/img/img-jumelage/imgJumelage-2019-01-356x250.jpg";
import img201902 from "../../../assets/img/img-jumelage/imgJumelage-2019-02-337x270.jpg";
import img201903 from "../../../assets/img/img-jumelage/imgJumelage-2019-03-337x240.jpg";
import img201904 from "../../../assets/img/img-jumelage/imgJumelage-2019-04-296x198.jpg";
import img201905 from "../../../assets/img/img-jumelage/imgJumelage-2019-05-281x221.jpg";
import img201906 from "../../../assets/img/img-jumelage/imgJumelage-2019-06-625x240.jpg";
// ************************ images 2023
import img202301 from "../../../assets/img/img-jumelage/imgJumelage-2023-01-768x1024.jpg";
import img202302 from "../../../assets/img/img-jumelage/imgJumelage-2023-02-1600x1200.jpg";
import img202303 from "../../../assets/img/img-jumelage/imgJumelage-2023-03-1200x1600.jpg";
import img202304 from "../../../assets/img/img-jumelage/imgJumelage-2023-04-1600x720.jpg";
import img202305 from "../../../assets/img/img-jumelage/imgJumelage-2023-05-1600x720.jpg";
import img202306 from "../../../assets/img/img-jumelage/imgJumelage-2023-06-1600x720.jpg";
import img202307 from "../../../assets/img/img-jumelage/imgJumelage-2023-07-1400x840.jpg";
import img202308 from "../../../assets/img/img-jumelage/imgJumelage-2023-08-1600x1200.jpg";
import img202309 from "../../../assets/img/img-jumelage/imgJumelage-2023-09-1600x720.jpg";
import img202310 from "../../../assets/img/img-jumelage/imgJumelage-2023-10-1834x724.jpeg";
import img202311 from "../../../assets/img/img-jumelage/imgJumelage-2023-11-768x1024.jpg";
import img202312 from "../../../assets/img/img-jumelage/imgJumelage-2023-12-1200x1600.jpg";
import img202313 from "../../../assets/img/img-jumelage/imgJumelage-2023-13-2000x900.jpg";
import img202314 from "../../../assets/img/img-jumelage/imgJumelage-2023-14-1600x720.jpg";
import img202315 from "../../../assets/img/img-jumelage/imgJumelage-2023-15-1600x720.jpg";
import img202316 from "../../../assets/img/img-jumelage/imgJumelage-2023-16-1600x720.jpg";
import img202317 from "../../../assets/img/img-jumelage/imgJumelage-2023-17-720x1600.jpg";
import img202318 from "../../../assets/img/img-jumelage/imgJumelage-2023-18-1200x1600.jpg";
import img202319 from "../../../assets/img/img-jumelage/imgJumelage-2023-19-768x1024.jpg";
import img202320 from "../../../assets/img/img-jumelage/imgJumelage-2023-20-1080x1080.jpg";
import img202321 from "../../../assets/img/img-jumelage/imgJumelage-2023-21-1024x768.jpg";
import img202322 from "../../../assets/img/img-jumelage/imgJumelage-2023-22-1200x1600.jpg";
import img202323 from "../../../assets/img/img-jumelage/imgJumelage-2023-23-1600x1200.jpg";
import img202324 from "../../../assets/img/img-jumelage/imgJumelage-2023-24-1600x720.jpg";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const tabJumelage = [
  {
    id: 1,
    season: "2018",
    num: 0,
    date: "14/07/2018",
    comment: "",
    images: [
      { img: img201801, width: "1000", height: "563" },
      { img: img201802, width: "1200", height: "675" },
      { img: img201803, width: "720", height: "540" },
      { img: img201804, width: "1000", height: "563" },
      { img: img201805, width: "800", height: "486" },
      { img: img201806, width: "600", height: "450" },
      { img: img201807, width: "600", height: "450" },
      { img: img201808, width: "600", height: "800" },
      { img: img201809, width: "800", height: "600" },
      { img: img201810, width: "1200", height: "675" },
      { img: img201811, width: "800", height: "450" },
      { img: img201812, width: "1000", height: "563" },
      { img: img201813, width: "900", height: "506" },
      { img: img201814, width: "900", height: "506" },
      { img: img201815, width: "1000", height: "563" },
      { img: img201816, width: "700", height: "394" },
      { img: img201817, width: "1000", height: "625" },
      { img: img201818, width: "900", height: "506" },
      { img: img201819, width: "800", height: "450" },
      { img: img201820, width: "900", height: "506" },
      { img: img201821, width: "1000", height: "563" },
      { img: img201822, width: "900", height: "1131" },
      { img: img201823, width: "1000", height: "563" },
      { img: img201824, width: "900", height: "506" },
    ],
  },
  {
    id: 2,
    season: "2019",
    num: 0,
    date: "15/07/2019",
    comment: "",
    images: [
      { img: img201901, width: "356", height: "250" },
      { img: img201902, width: "337", height: "270" },
      { img: img201903, width: "337", height: "240" },
      { img: img201904, width: "296", height: "198" },
      { img: img201905, width: "281", height: "221" },
      { img: img201906, width: "625", height: "240" },
    ],
  },
  {
    id: 3,
    season: "2023",
    num: 0,
    date: "14/07/2023",
    comment: "",
    images: [
      { img: img202301, width: "768", height: "1024" },
      { img: img202302, width: "1600", height: "1200" },
      { img: img202303, width: "1200", height: "1600" },
      { img: img202304, width: "1600", height: "720" },
      { img: img202305, width: "1600", height: "720" },
      { img: img202306, width: "1600", height: "720" },
      { img: img202307, width: "1400", height: "840" },
      { img: img202308, width: "1600", height: "1200" },
      { img: img202309, width: "1600", height: "720" },
      { img: img202310, width: "1834", height: "724" },
      { img: img202311, width: "768", height: "1024" },
      { img: img202312, width: "1200", height: "1600" },
      { img: img202313, width: "2000", height: "900" },
      { img: img202314, width: "1600", height: "720" },
      { img: img202315, width: "1600", height: "720" },
      { img: img202316, width: "1600", height: "720" },
      { img: img202317, width: "720", height: "1600" },
      { img: img202318, width: "1200", height: "1600" },
      { img: img202319, width: "768", height: "1024" },
      { img: img202320, width: "1080", height: "1080" },
      { img: img202321, width: "1024", height: "768" },
      { img: img202322, width: "1200", height: "1600" },
      { img: img202323, width: "1600", height: "1200" },
      { img: img202324, width: "1600", height: "720" },
    ],
  },
];

const photosJumelage = tabJumelage.map((photo) => ({
  id: photo.id,
  season: photo.season,
  num: photo.num,
  date: photo.date,
  comment: photo.comment,
  images: photo.images.map((image) => {
    return {
      src: image.img,
      width: image.width,
      height: image.height,
      srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((image.height / image.width) * breakpoint);
        return {
          src: image.img,
          width: breakpoint,
          height: height,
        };
      }),
    };
  }),
}));

export default photosJumelage;
