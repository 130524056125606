import { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../__helper/supabaseClient";

const AuthContext = createContext({
  isAuthenticated: false,
  user: "",
  adminLogin: () => {},
  adminRegister: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  //************ Check Supabase Session opened ************************************/
  useEffect(() => {
    const fetchSession = async () => {
      try {
        // Vérifier l'état de l'authentification au chargement ********************/
        const { data, error } = await supabase.auth.getSession();

        if (error) {
          console.error("Problème de connexion au serveur supabase : ", error);
          return;
        }

        setUser(data?.session?.user ?? null);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSession();

    // Écouter les changements d'authentification **********************************/
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      console.log("onAuthStateChange", event);

      if (event === "SIGNED_OUT") {
        setUser(null);
      } else {
        setUser(session?.user ?? null);
      }
    });

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  //************ Sign In Function *************************************************/
  const adminLogin = async (userIdAdmin, pwdAdmin) => {
    console.log("Connexion ________________");

    const { data, error } = await supabase.auth.signInWithPassword({
      email: userIdAdmin,
      password: pwdAdmin,
    });

    if (error) {
      // console.error("Aucun compte trouvé ou mot de passe erroné !");
      return {
        success: false,
        message: "Aucun compte trouvé ou mot de passe erroné !",
      };
    }

    if (!isAuthenticated) {
      setIsAuthenticated(true);
    }
    console.log("isAuthenticated: ", isAuthenticated);
    console.log("DATA: ", data);

    return { success: true, data };
  };

  //************ Sign Up Function *************************************************/
  const adminRegister = async (userIdAdmin, pwdAdmin) => {
    console.log("Enregistrement ________________");

    const { data, error } = await supabase.auth.signUp({
      email: userIdAdmin,
      password: pwdAdmin,
    });

    if (error) {
      console.error("adminRegister error");
      return { success: false, error };
    }

    return { success: true, data };
  };

  //************ Sign Out function *************************************************/
  const logout = () => {
    console.log("Déconnexion ________________");

    if (user) {
      const { error } = supabase.auth.signOut();

      if (error) {
        console.error("Problème de déconnexion au serveur : ", error);
      } else {
        console.log("Déconnexion réussie !");
      }

      setUser(undefined);
      setIsAuthenticated(false);

      navigate("admin/auth/login", { replace: true });
    } else {
      console.error("No user session found !");
    }
  };

  //************ Définition of useAuth values **************************************/
  const value = {
    isAuthenticated,
    user,
    adminLogin,
    adminRegister,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && <>{children}</>}
    </AuthContext.Provider>
  );
};
