import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosFeteEcole from "../../../__params/tab-pages/animations/tab-feteecole";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const FeteEcole = () => {
  const [indexPhoto, setIndexPhoto] = useState(-1);
  const [tabImages, setTabImages] = useState([]);

  //
  /* Tri de la table des photos de fin d'année en ordre décroissant */
  //
  const photosSorted = photosFeteEcole.sort((a, b) => b.id - a.id);

  //
  /* Récupération des saisons existant dans la table photos de fin d'année */
  //
  const tabSaison = [];

  photosSorted.map((saison) => {
    let season = "";
    if (saison.id !== season) {
      season = saison.id;
      /* La propriété id est nécessaire pour compatibilité avec les autres tables utilisant MenuSaison */
      tabSaison.push({ id: saison.id, season: saison.id });
    }
    return {
      tabSaison,
    };
  });

  const handlePhotoClicked =
    (season) =>
    ({ index }) => {
      // Récupérer les photos de la bonne saison, bonne édition

      // Filtrer les images pour la date "08/02/2025"
      const imagesClicked = photosSorted
        .filter((saison) => saison.season === season)
        .flatMap((session) => session.images);
      // setTabImages(imagesClicked.shift());
      setTabImages(imagesClicked);

      setIndexPhoto(index);
    };

  return (
    <main role="main" className="mc-fond-courts-couverts">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Partie Fete Ecole */}
            <Card bg="secondary" className="m-2 border-0">
              <Card.Header className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0 text-center bg-white">
                Fête de l'école de TENNIS - fin juin
              </Card.Header>
            </Card>
            {/************************** Partie Fête École */}
            {photosSorted.map((saison, index) => {
              return (
                <Card key={index} bg="transparent" className="m-2 border-0">
                  <Card.Body
                    style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                    className="m-2 p-4"
                  >
                    <PhotoAlbum
                      layout="rows"
                      photos={saison.images}
                      onClick={handlePhotoClicked(saison.season)}
                    />
                  </Card.Body>
                </Card>
              );
            })}
            <Lightbox
              slides={tabImages}
              open={indexPhoto >= 0}
              index={indexPhoto}
              close={() => setIndexPhoto(-1)}
              // enable optional lightbox plugins
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default FeteEcole;
