import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosEvents30Ans from "../../../__params/tab-pages/events/tab-3040ans";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Events30Ans = () => {
  const [indexPhoto, setIndexPhoto] = useState(-1);

  const { navbarHeight } = useNavHeight();

  const photosEvents30Ans2013 = [];

  //
  // Selection des photos en fonction de l'année
  //
  photosEvents30Ans.map((photo, index) => {
    if (photo.id === "2013") {
      photosEvents30Ans2013.push(photo);
    }
    return {
      photosEvents30Ans2013,
    };
  });

  return (
    <main role="main" className="mc-fond-clubhouse">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              background: "rgba(0, 43, 110, 0.5)",
              zIndex: "1000",
            }}
          >
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Partie Events30Ans */}
            <Card bg="primary" className="m-2 border-0">
              <Card.Header
                className="mc-rampart m-3 text-primary rounded-0 text-center bg-white"
                // style={{ backgroundColor: "rgba(255, 255, 255, 0.3)" }}
              >
                Le TCF Fête ses 30 ans
              </Card.Header>
              <Card.Text className="text-light text-center fw-bold">
                le 6 avril 2013
              </Card.Text>
            </Card>
            {/************************** Partie Events30Ans 2023 */}
            <Card bg="transparent" className="m-2 border-0">
              <Card.Body
                style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                className="m-2 p-4"
              >
                <PhotoAlbum
                  layout="rows"
                  photos={photosEvents30Ans2013}
                  onClick={({ index }) => setIndexPhoto(index)}
                />
              </Card.Body>
            </Card>
            <Lightbox
              slides={photosEvents30Ans2013}
              open={indexPhoto >= 0}
              index={indexPhoto}
              close={() => setIndexPhoto(-1)}
              // enable optional lightbox plugins
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Events30Ans;
