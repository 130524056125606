import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import * as formik from "formik";
import loginSchema from "../../__params/validationschema/login/loginSchema";
import loginInitialesValues from "../../__params/validationschema/login/loginInitValues";
import { IconContext } from "react-icons";
import FaIcons from "../../assets/icons/faicons";
import { useAuth } from "../../__utils/context/authprovider";

const Login = () => {
  const navigate = useNavigate();

  const { Formik } = formik;

  const { adminLogin } = useAuth();

  const [errConnexion, setErrConnexion] = useState(null);
  // const [errIdentifiant, setErrIdentifiant] = useState(null);
  // const [successMsg, setSuccessMsg] = useState(null);

  /*************************************************** handle Submit *************/
  const handleLogin = async (values) => {
    setErrConnexion(null);

    await adminLogin(values.userIdAdmin, values.pwdAdmin)
      .then((adminLoginData) => {
        if (adminLoginData.success) {
          navigate("/admin/gestionclub");
        } else {
          setErrConnexion(adminLoginData.message);
        }
      })
      .catch((err) => setErrConnexion(err));
  };
  /*************************************************** end handle Submit *************/

  return (
    <section>
      <Container
        fluid
        className="gx-0"
        style={{
          backgroundColor: "rgba(135, 209, 249, 0.3)",
        }}
      >
        <Row className="gx-0">
          {/* Photo de fond de page login */}
          <Col md={6}>
            <div
              className="g-0 d-none d-md-block mc-fond-login"
              style={{ height: "60vh" }}
            ></div>
          </Col>

          {/* Formik form */}
          <Col md={6} className="px-3">
            <Formik
              validationSchema={loginSchema}
              onSubmit={handleLogin}
              initialValues={loginInitialesValues}
            >
              {({
                handleSubmit,
                handleBlur,
                handleChange,
                isSubmitting,
                values,
                touched,
                errors,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="px-1"
                  style={{ height: "auto" }}
                >
                  <Row className="gx-0">
                    <h1 className="mc-rampart my-4 text-primary text-center">
                      Espace Admin
                    </h1>
                  </Row>
                  <Row className="gx-0">
                    {/* Identifiant */}
                    <Form.Group
                      className="w-75 mx-auto my-2"
                      controlId="validationFormikUserIdAdmin"
                    >
                      <Form.Label className="mc-rfs-6 m-0 text-primary fw-bold">
                        Email
                      </Form.Label>
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-primary mb-2"
                        type="text"
                        placeholder="email@xxx.com"
                        name="userIdAdmin"
                        value={values.userIdAdmin}
                        aria-describedby="inputGroupPrepend"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.userIdAdmin && !errors.userIdAdmin}
                        isInvalid={touched.userIdAdmin && !!errors.userIdAdmin}
                      />

                      <Form.Control.Feedback type="invalid">
                        {errors.userIdAdmin}
                      </Form.Control.Feedback>

                      {/* {message && !errors.userIdAdmin ? (
                        <small className="text-danger">{message}</small>
                      ) : null} */}
                    </Form.Group>

                    {/* Mot de passe */}
                    <Form.Group
                      className="w-75 my-2 mx-auto"
                      controlId="validationFormikPwdAdmin"
                    >
                      <Form.Label className="mc-rfs-6 m-0 text-primary fw-bold">
                        Mot de passe :
                      </Form.Label>
                      <Form.Control
                        autoComplete="on"
                        className="mc-rfs-6 border border-1 border-primary"
                        type="password"
                        placeholder="entrez le mot passe"
                        name="pwdAdmin"
                        value={values.pwdAdmin}
                        aria-describedby="inputGroupPrepend"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isValid={touched.pwdAdmin ?? !errors.pwdAdmin}
                        isInvalid={touched.pwdAdmin && !!errors.pwdAdmin}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.pwdAdmin}
                      </Form.Control.Feedback>

                      {/* {message && !errors.pwdAdmin ? (
                        <small className="text-danger">{message}</small>
                      ) : null} */}
                    </Form.Group>
                  </Row>
                  {/******************************** login error message *************************/}
                  <Row className="gx-0 my-4">
                    <Row>
                      {errConnexion && (
                        <div className="text-danger text-center fst-italic fw-bold">
                          {errConnexion}
                        </div>
                      )}
                    </Row>
                    {/* Bouton de connexion */}
                    <Button
                      variant="primary"
                      disabled={
                        isSubmitting || errors.userIdAdmin || errors.pwdAdmin
                      }
                      type="submit"
                      className="mc-btn-neon mt-5 mx-auto px-4 d-block w-50"
                    >
                      <span className="fw-bold mc-rfs-5 pe-2">
                        Se connecter
                      </span>
                      <IconContext.Provider value={{ size: "1rem" }}>
                        <FaIcons.FaChevronRight />
                      </IconContext.Provider>
                    </Button>

                    {/* Lien de navigation vers register */}
                    <Nav className="d-flex justify-content-center mt-5">
                      <Nav.Item>
                        <NavLink to="/admin/auth/register">
                          <span className="mc-rfs-6 fw-bold text-primary">
                            Créer un compte
                          </span>
                        </NavLink>
                        <span className="mc-rfs-6 fst-italic">
                          {" "}
                          (réservé aux membres du bureau du TCF)
                        </span>
                      </Nav.Item>
                    </Nav>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Login;
