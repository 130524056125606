import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import PhotoAlbum from "react-photo-album";
import photosPickleball from "../../../__params/tab-pages/animations/tab-pickleball";
import MenuSaison from "../../../components/menus/m-saison";
import MenuBreadcrumbEvents from "../../../components/menus/m-breadcrumb";
import { useNavHeight } from "../../../__utils/context/navheightprovider";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

const Pickleball = () => {
  const [indexPhoto, setIndexPhoto] = useState(-1);
  const [tabImages, setTabImages] = useState([]);

  const { navbarHeight, sideNavHeight, breadNavHeight } = useNavHeight();
  //
  /* Tri de la table des photos de fin d'année en ordre décroissant */
  //
  const photosSorted = photosPickleball.sort((a, b) => b.season - a.season);
  //
  /* Récupération des saisons existant dans la table photos pickleball */
  //
  const tabSaison = [];
  let season = "";

  photosSorted.map((saison) => {
    if (saison.season !== season) {
      season = saison.season;
      /* La propriété id est nécessaire pour compatibilité avec les autres tables utilisant MenuSaison */
      tabSaison.push({ id: season, season: saison.season });
    }
    return {
      tabSaison,
    };
  });
  /* FIN - Récupération des saisons existant dans la table photos pickleball */

  /* Positionnement en haut de page à l'afichage de la page ******************/
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const handlePhotoClicked =
    (season, date) =>
    ({ index }) => {
      // Récupérer les photos de la bonne saison, bonne édition

      // Filtrer les images pour la date "08/02/2025"
      const imagesSeason = photosSorted.filter(
        (saison) => saison.season === season
      );

      const imagesClicked = imagesSeason.map((item) =>
        item.sessions
          .filter((session) => session.date === date)
          .flatMap((session) => session.images)
      );
      setTabImages(imagesClicked.shift());

      setIndexPhoto(index);
    };

  return (
    <main role="main" className="mc-fond-pickleball">
      <Container fluid className="gx-0">
        <Row className="gx-0">
          <Col
            md={3}
            className="gx-0"
            style={{
              position: "sticky",
              top: navbarHeight,
              zIndex: "1000",
            }}
          >
            <MenuSaison saisons={tabSaison} />
          </Col>
          <Col md={9} className="gx-0">
            {/***************************** menu breadcrumb */}
            <MenuBreadcrumbEvents />
            {/************************** Titre Pickleball */}
            <Card bg="warning" className="m-2 border-0">
              <Card.Header
                className="mc-rampart mc-rfs-2 m-3 text-primary rounded-0 text-center bg-warning-subtle"
                style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
              >
                Tournois de Pickleball
              </Card.Header>
            </Card>
            {/************************** Partie Pickleball */}
            {photosSorted.map((saison) => {
              return (
                <div key={saison.id}>
                  <Card bg="transparent" className="my-2 border-0">
                    <Card.Text>
                      <span
                        className="mc-marker"
                        style={{
                          top: -(navbarHeight + sideNavHeight + breadNavHeight),
                        }}
                        id={`saison${saison.season}`}
                      ></span>
                    </Card.Text>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}
                      className="m-2 p-2 rounded"
                    >
                      <Card.Header
                        className="mc-rampart mc-rfs-3 m-2 text-primary rounded"
                        style={{ backgroundColor: "rgba(255, 255, 255, 1)" }}
                      >
                        {`SAISON ${saison.season}`}
                      </Card.Header>
                    </Card.Body>
                    <Card.Body
                      style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}
                      className="m-2"
                    >
                      {saison.sessions.map((session, index) => {
                        return (
                          <div key={index} className="mb-4">
                            <Card.Text className="text-dark mc-rfs-4 fw-bold">
                              {session.num === 0 ? (
                                `Édition du ${session.date} - 
                              ${session.comment}`
                              ) : (
                                <>
                                  <span>
                                    {session.num} doublettes lors de l'édition
                                    du {session.date}
                                  </span>
                                  <br />
                                  <span className="mc-rfs-5">
                                    {session.comment}
                                  </span>
                                </>
                              )}
                            </Card.Text>
                            <PhotoAlbum
                              layout="rows"
                              photos={session.images}
                              onClick={handlePhotoClicked(
                                saison.season,
                                session.date
                              )}
                            />
                          </div>
                        );
                      })}
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
            <Lightbox
              slides={tabImages}
              open={indexPhoto >= 0}
              index={indexPhoto}
              close={() => setIndexPhoto(-1)}
              // enable optional lightbox plugins
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Pickleball;
