import React from "react";
import { Routes, Route } from "react-router-dom";
import ErrorPage from "../../components/error/c-errorpage";
import { GestionClub, Tarifs, FicheMembre, ListeDesMembres } from "./index";

const AdminRoute = () => {
  return (
    <Routes>
      <Route index element={<GestionClub />} />
      <Route path="/gestionclub" element={<GestionClub />} />
      <Route>
        <Route path="/tarifs" element={<Tarifs />} />
        <Route path="/fichemembre" element={<FicheMembre />} />
        <Route path="/listedesmembres" element={<ListeDesMembres />} />
      </Route>
      {/******************************* Page Not Found ******************************/}
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
};

export default AdminRoute;
